<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'customFieldEdit', action: null })" size="lg" :warning="field.archived"
    :hideFooter="!!(editAction && editAction.id)" :title="modalTitle">
    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="!!fieldId" />
    </template>

    <gateKeeper v-if="customFields" feature="custom_fields" :blockIfLimitReached="editAction && !editAction.id"
      :limitedItemsCount="activeCustomFields.length" class="pb-5" dontNagIfLimitReached>
      <div class="w-100">
        <b-form-radio-group buttons v-model="field.type" button-variant="outline-primary" size="sm" class="mb-3">
          <b-form-radio v-for="type in customFieldTypes" :value="type.id" :key="type.id"> {{ type.title }} </b-form-radio>
        </b-form-radio-group>

        <wiskInputGroup @errorCountChanged="setValidState" :disabled="disabled" style="height: 400px; width: 650px;" :key="field.type">
          <wiskInput infoTooltipKey="c9516167-8d70-4822-973a-46c63be7fa59" :label="translations.txtCustomFieldType" :modelValue="selectedFieldType.title" disabled v-if="!!fieldId && selectedFieldType" />

          <wiskInput infoTooltipKey="29a56c9f-2f2e-4b75-a968-b83095ee3201" :label="translations.txtGenericLabel" v-model="field.label" required :validations="validationsTitle" />

          <wiskInput infoTooltipKey="c692a3b9-8027-496a-99ea-16a3d85823f3" :label="translations.txtGenericSymbol" v-model="field.symbol" required v-if="field.type === 'currency'" />

          <wiskInput infoTooltipKey="79c3db14-e2a2-4142-bc20-856b0c191197" :label="translations.txtGenericDecimalPlaces" v-model="field.decimal_places" inputType="number" v-if="field.type === 'decimal_number'" />

          <!-- <genericInput :label="translations.txtCustomFieldDefaultValue" v-model="field.default_value" v-if="field.type !== 'checkmark' && field.type !== 'images'" :prefix="field.symbol"
            :decimals="decimals" v-bind="customFieldsTypeTranslations[field.type]" :items="prepareItemsForWiskInput(field.values)" />
            not used yet, maybe never will-->

          <b-card v-if="field.type === 'multiselect' || field.type === 'dropdown'">
            <wiskInput infoTooltipKey="0b146cf2-0110-4b2b-9ca2-b93dbd46f707" label="validation helper - should be hidden" style="display: none;" inputType="number" :modelValue="field.values.length" required dummy class="d-none" />

            <template v-slot:header>
              <span class=""> {{ translations.txtCustomFieldDropdownItems }} </span>
              <b-button variant="link" size="sm" title="Add" class="p-0 float-end" @click="addValueToMultiselect">
                <icon class="" name="wisk-plus" :scale="1.0"></icon>
              </b-button>
            </template>
            <b-row no-gutters gap="5px">
              <span v-for="(value, index) in field.values" :key="index" class="badge bg-primary d-inline-flex align-items-center mb-0">
                <span v-if="field.type === 'multiselect'" class="px-1">{{ value.title }}</span>
                <span v-else class="px-1">{{ value }}</span>
                <button type="button" class="close btn-close ms-1 me-0 p-0" aria-label="Close" @click="removeValueFromMultiselect(value)">
                  <span aria-hidden="true">&times;</span>
                </button>
              </span>
            </b-row>
          </b-card>

        </wiskInputGroup>
      </div>

    </gateKeeper>

    <confirm ref="confirmDialog" size="md" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="editAction && !editAction.id ? save : undefined" />
    </template>

  </wiskModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import isFinite from 'lodash.isfinite'
import isEqual from 'lodash.isequal'
import { prepareItemsForWiskInput, guid } from '@/modules/utils'
import editorButtons from '@/components/common/WiskEditorModalButtons'

export default {
  name: 'CustomFieldEdit',
  components: { editorButtons },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      valid: true,
      disabled: false,
      initDone: false,
      prepareItemsForWiskInput,
      onChangeTimeoutId: null,
      operations: [],
      field: {
        type: null,
        default_value: null,
        symbol: null,
        decimal_places: null,
        label: null,
        values: []
      }
    }
  },
  computed: {
    ...mapGetters(['customFieldTypes', 'customFieldsTypeTranslations']),
    ...mapState(['translations', 'customFieldsById', 'customFields']),
    activeCustomFields() {
      return this.customFields.filter(f => !f.archived && f.item_type !== 'movement')
    },
    targets() {
      return {
        item: this.translations.txtGenericWiskItem,
        movement: this.translations.grpMovementTypes.intake,
        pos_item: this.translations.txtGenericPOSItem,
        subrecipe: this.translations.txtSubrecipeTitle,
        distributor: this.translations.txtGenericDistributor,
        inventory: this.translations.txtMenuInventory,
        independent_inventory: this.translations.txtIndependentInventory,
        venue: this.translations.txtGenericVenue,
        draft_invoice: this.translations.txtScannedInvoice,
        purchase_order: this.translations.txtPurchaseOrder,
      }
    },
    validationsTitle() {
      return [
        {
          type: 'titleUniq',
          validator: value => !(value && this.customFields.find(c => c.item_type === this.editAction.target && c.label.toLowerCase() === value.toLowerCase())),
          message: this.translations.translate('tplValidationCustomFieldLabel', { '{a}': this.targets[this.editAction.target] })
        }
      ]
    },
    pageActions() {
      return [
        { key: 1, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.field.archived, action: this.toggleArchived },
        {
          key: 2,
          title: this.translations.txtGenericRestore,
          icon: 'wisk-history',
          variant: 'success',
          hide: !this.field.archived,
          action: this.toggleArchived,
          planFeature: 'custom_fields',
          planFeatureLimitedItemsCount: this.activeCustomFields.length
        }
      ]
    },
    decimals() {
      if (this.field) {
        if (isFinite(this.field.decimal_places)) {
          return this.field.decimal_places
        }

        if (this.customFieldsTypeTranslations && this.customFieldsTypeTranslations[this.field.type]) {
          return this.customFieldsTypeTranslations[this.field.type].decimals || 0
        }
      }

      return 0
    },
    selectedFieldType() {
      if (this.customFieldTypes && this.customFieldTypes.length && this.editAction && this.editAction.id && this.field.type) {
        let found = this.customFieldTypes.find(t => t.id === this.field.type)
        return found || null
      }
      return null
    },
    modalTitle() {
      if (this.editAction && this.editAction.id && this.field.label && this.field.type) {
        let archivedLabel = ''
        if (this.field.archived) {
          archivedLabel = ` - (${this.translations.txtGenericArchived})`
        }
        return `${this.field.type}: ${this.field.label}${archivedLabel}`
      }
      return this.translations.txtCustomFieldAdd
    },
    operationsComputed() {
      return [
        { type: 'label', value: this.field.label },
        { type: 'item_type', value: this.editAction.target },
        { type: 'type_definition', value: this.field }
      ]
    },
    fieldId() {
      return (this.editAction && this.editAction.id) || 0
    }
  },
  methods: {
    ...mapActions(['setCustomField', 'setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount && !!this.field.type
    },
    addValueToMultiselect() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.prompt({
          callback: title => {
            if (title) {
              let value = title
              if (this.field.type === 'multiselect') {
                value = { uuid: guid(), title }
              }
              this.field.values.push(value)

              this.onChange()
            }
          },
          message: this.translations.txtCustomFieldAddValue,
          title: `${this.field.type}: ${this.field.label}`
        })
      }
    },
    removeValueFromMultiselect(item) {
      if (item) {
        let findBY = item.uuid || item,
          index = this.field.values.findIndex(v => v.uuid === findBY || v === findBY)
        if (index > -1) {
          this.field.values.splice(index, 1)
          this.onChange()
        }
      }
    },
    save(operation) {
      this.setCustomField({ id: this.fieldId, operation: operation || this.operations }).finally(() => {
        this.setGlobalAction({ type: 'customFieldEdit', action: null })
      })
    },
    toggleArchived() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ type: 'archive', value: !this.field.archived })
          },
          message: this.field.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.field.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    onChange() {
      if (this.initDone) {
        clearTimeout(this.onChangeTimeoutId)

        this.onChangeTimeoutId = setTimeout(() => {
          if (this.fieldId) {
            this.setCustomField({ id: this.fieldId, operation: this.operations })
          }
        }, 1200)
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initDone = true
    }, 500)
  },
  watch: {
    operations: {
      handler: 'onChange'
    },
    operationsComputed: {
      handler(operationsComputed) {
        if (!isEqual(operationsComputed, this.operations)) {
          this.operations = [...operationsComputed]
        }
      },
      deep: true
    },
    'field.type': {
      handler() {
        if (this.field.values.length && !this.editAction.id) {
          this.field.values = []
          this.field.default_value = null
          this.field.symbol = null
        }
      }
    },
    fieldId: {
      handler(fieldId) {
        if (fieldId) {
          let field = this.customFieldsById[fieldId]
          this.field = {
            values: [],
            label: field.label,
            archived: field.archived,
            ...field.type_definition
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
