<template>
  <wiskModal :visible="!!editAction" @hide="hide" size="lg" hideHeaderExtra :title="translations.txtItemErrorFound"
    :okText="translations.txtGenericRetry" @ok="retryOperation">
    <div v-if="movements?.length">
      <b-row class="">
        <b-col>
          <p style="white-space: pre-wrap;"> {{ translations.txtMovementBatchErrorMessage }} </p>
        </b-col>
      </b-row>

      <wiskGrid parentGridName="movementsBatchErrorHandlerGrid" :columnDefs="columnDefs" gridAutoHeight resetRows :rowData="movements" trackBy="id" />
    </div>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { formatDate } from '@/modules/utils'

export default {
  name: 'MovementsBatchErrorHandler',
  components: {},
  props: { editAction: Object },
  data() {
    return {
      movementIds: [],
      errorsById: {}
    }
  },
  computed: {
    ...mapState(['translations', 'movementsById']),
    ...mapGetters([]),
    movements() {
      return this.movementIds.map(id => ({ ...this.movementsById[id], reason: this.errorsById[id]?.value?.reason || this.translations.txtGenericErrorNo }))
    },
    columnDefs() {
      return [
        {
          headerName: this.translations.txtMovementInvoice,
          colId: 'title',
          minWidth: 200,
          width: 300,
          field: 'description'
        },
        {
          headerName: this.translations.txtScannedInvoicesInvoiceDate,
          minWidth: 140,
          width: 180,
          colId: 'date',
          field: 'date',
          valueFormatter: params => formatDate(params.value)
        },
        {
          headerName: this.translations.txtGenericError,
          minWidth: 250,
          width: 400,
          colId: 'reason',
          field: 'reason'
        },
        {
          colId: 'remove',
          sortOrder: 26000,
          headerName: this.translations.txtMovementErrorRemoveFromRetryList,
          minWidth: 80,
          width: 100,
          cellClass: ['cell-more-details'],
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRenderer: 'CellMoreDetails',
          cellRendererParams: {
            iconClass: 'text-danger',
            excludeFromExport: true,
            icon: 'wisk-trash',
            onClick: this.removeFromList
          },
          valueGetter: params => ({
            id: (params.data && params.data.id) || '',
            group: !!params.node.group
          })
        },
        {
          colId: 'more',
          sortOrder: 27000,
          headerName: this.translations.txtGenericEdit,
          minWidth: 80,
          width: 100,
          cellClass: ['cell-more-details'],
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRenderer: 'CellMoreDetails',
          cellRendererParams: {
            excludeFromExport: true,
            onClick: this.openEditMovement
          },
          valueGetter: params => ({
            id: (params.data && params.data.id) || '',
            group: !!params.node.group
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'getMovements']),
    openEditMovement(id) {
      this.setGlobalAction({ type: 'movementEdit', action: { id, onChange: this.getMovements } })
    },
    removeFromList(id) {
      this.movementIds = this.movementIds.filter(movementId => movementId !== id)
    },
    retryOperation() {
      if (this.movementIds?.length) {
        this.editAction.retry(this.movementIds)
      }

      this.hide()
    },
    hide() {
      this.setGlobalAction({ type: 'movementsBatchErrorHandler', action: null })
    },
  },
  created() { },
  watch: {
    editAction: {
      immediate: true,
      handler(editAction) {
        if (editAction) {
          this.movementIds = editAction.movementIds || []
          this.errorsById = editAction.errorsById || {}
        }
      }
    }

  }
}
</script>

<style lang="scss"></style>
