import get from 'lodash.get'
import { formatDate, currencyFormatHideZero, toFixedHideZero, convertUM, getMeasurementType, getMeasurementShortName } from '@/modules/utils'

const gridOptions = {}

const getItemsGridColumns = ({ translations, showMovements, showByLocation, hasLocation, openInventory, defaultUMVolume, defaultUMWeight }) => ({
  image: {
    hide: false
  },
  title: {
    hide: false,
    wiskBottomAggregation: translations.txtGenericTotal + ':'
  },
  costUnit: {
    cellRendererParams: {
      infoComponentType: null,
      readonly: true
    },
    hide: true,
    cellClass: ['currency']
  },
  costUnitInventory: {
    colId: 'costUnitInventory',
    sortOrder: 750,
    headerName: translations.txtInventoriesCostUnit,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 100,
    width: 100,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true,
      decimals: 2,
      mode: 'cost',
      infoComponentType: 'inventoryDetails',
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => {
      let consumptionItem = get(params, 'data.consumptionItem', {})

      return {
        id: params.data?.item_distributor_id ?? params.data?.variants?.find(v => v && v.is_default)?.id,
        item_id: params.data?.item_id,
        input_value: consumptionItem.cost_per_unit
      }
    }
  },
  location: {
    headerName: translations.txtGenericLocation,
    remove: !hasLocation,
    hide: true,
    suppressColumnsToolPanel: true,
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    sortOrder: 790,
    colId: 'location',
    valueGetter: params => get(params, 'data.consumptionItem.location.title', '')
  },
  family: {
    hide: true,
    cellRendererParams: {}
  },
  category: {
    hide: true,
    cellRendererParams: {}
  },
  distributor: { hide: true },
  distributorCode: { hide: true },
  retailPrice: { hide: true },
  retailValue: {
    colId: 'retailValue',
    sortOrder: 1760,
    hide: true,
    headerName: translations.txtGenericRetailValue,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 70,
    width: 70,
    enableRowGroup: true,
    keyCreator: params => params.value.input_value,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      translate: translations.translate,
      decimals: 2,
      readonly: true,
      prefix: window.WiskGlobals.currency
    },
    valueGetter: params => ({
      id: params.data && params.data.item_id,
      input_value: get(params, 'data.consumptionItem.consumption_units', 0) * get(params, 'data.retail_price', 0)
    })
  },
  fromInventoryUnits: {
    headerName: translations.txtConsumptionInventoryFrom,
    sortOrder: 1750,
    colId: 'fromInventoryUnits',
    wiskGetBottomAggregationValue: rowData => get(rowData, 'consumptionItem.from.units', 0) || 0,
    cellRenderer: 'CellNumber',
    aggFunc: 'wiskSum',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            let locationId = get(data, 'rowData.consumptionItem.location.id')
            openInventory(data.rowData.consumptionItem.from.id, locationId, data.rowData.item_id)
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.from.units', 0)
    })
  },
  fromInventoryDollars: {
    headerName: translations.txtConsumptionInventoryFromDollars,
    sortOrder: 1751,
    hide: true,
    colId: 'fromInventoryDollars',
    wiskGetBottomAggregationValue: rowData => get(rowData, 'consumptionItem.from.dollars', 0) || get(rowData, 'consumptionItem.from.units', 0) * rowData.price_per_unit || 0,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      prefix: window.WiskGlobals.currency,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.from.dollars', 0) || get(params, 'data.consumptionItem.from.units', 0) * get(params, 'data.price_per_unit', 0)
    })
  },
  movements: {
    colId: 'movements',
    headerName: translations.txtGenericDeliveries,
    sortOrder: 1755,
    cellRenderer: 'CellNumber',
    wiskGetBottomAggregationValue: rowData => rowData.consumptionItem.movements.units,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            showMovements(data.rowData.consumptionItem)
          },
          getVisible: data => get(data, 'consumptionItem.movements.details.length'),
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.movements.units', 0)
    })
  },
  movementsDollars: {
    colId: 'movementsDollars',
    headerName: translations.txtConsumptionMovementsDollars,
    sortOrder: 1756,
    hide: true,
    wiskGetBottomAggregationValue: rowData => get(rowData, 'consumptionItem.movements.dollars', 0) || get(rowData, 'consumptionItem.movements.units', 0) * rowData.price_per_unit || 0,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      prefix: window.WiskGlobals.currency,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.movements.dollars', 0) || get(params, 'data.consumptionItem.movements.units', 0) * get(params, 'data.price_per_unit', 0)
    })
  },
  toInventoryUnits: {
    headerName: translations.txtConsumptionInventoryTo,
    sortOrder: 1770,
    colId: 'toInventoryUnits',
    cellRenderer: 'CellNumber',
    wiskGetBottomAggregationValue: rowData => rowData.consumptionItem.to.units,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            let locationId = get(data, 'rowData.consumptionItem.location.id')
            openInventory(data.rowData.consumptionItem.to.id, locationId, data.rowData.item_id)
          },
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.to.units', 0)
    })
  },
  toInventoryDollars: {
    headerName: translations.txtConsumptionInventoryToDollars,
    sortOrder: 1771,
    hide: true,
    colId: 'toInventoryDollars',
    wiskGetBottomAggregationValue: rowData => get(rowData, 'consumptionItem.to.dollars') || get(rowData, 'consumptionItem.to.units', 0) * rowData.price_per_unit || 0,
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      prefix: window.WiskGlobals.currency,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.to.dollars', 0) || get(params, 'data.consumptionItem.to.units', 0) * get(params, 'data.price_per_unit', 0)
    })
  },
  consumptionBottles: {
    headerName: translations.txtConsumptionBottles,
    sortOrder: 1780,
    colId: 'consumptionBottles',
    cellRenderer: 'CellNumber',
    keyCreator: params => params.value.input_value,
    wiskGetBottomAggregationValue: rowData => rowData.consumptionItem.consumption_units,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      readonly: true,
      infoComponentType: 'extraButtons',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            showByLocation(data.rowData.consumptionItem)
          },
          getVisible: data => data && data.consumptionItem && data.consumptionItem.by_location && data.consumptionItem.by_location.length,
          icon: 'wisk-arrow-right-circle'
        }
      ]
    },
    valueGetter: params => ({
      id: params.data && params.data.combined_id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.consumptionItem.consumption_units', 0)
    })
  },
  consumptionUMVolume: {
    headerName: translations.translate('tplVarianceConsumptionUM', { '{a}': getMeasurementShortName(defaultUMVolume) }),
    sortOrder: 1785,
    hide: true,
    minWidth: 150,
    colId: 'consumptionUMVolume',
    cellRenderer: 'CellNumber',
    keyCreator: params => params.value.input_value,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 1,
      readonly: true,
      suffix: getMeasurementShortName(defaultUMVolume)
    },
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        id: params?.data?.id,
        group: !!params.node.group,
        input_value: measurementType === 'volume' ? convertUM({ from: { quantity: get(params, 'data.consumptionItem.quantity'), unit_of_measurement: 'ml' }, to: defaultUMVolume }) : 0
      }
    }
  },
  consumptionUMWeight: {
    headerName: translations.translate('tplVarianceConsumptionUM', { '{a}': getMeasurementShortName(defaultUMWeight) }),
    sortOrder: 1790,
    hide: true,
    colId: 'consumptionUMWeight',
    cellRenderer: 'CellNumber',
    minWidth: 150,
    keyCreator: params => params.value.input_value,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 1,
      readonly: true,
      suffix: getMeasurementShortName(defaultUMWeight)
    },
    valueGetter: params => {
      let measurementType = getMeasurementType(params?.data?.measurement)

      return {
        id: params?.data?.id,
        group: !!params.node.group,
        input_value: measurementType === 'weight' ? convertUM({ from: { quantity: get(params, 'data.consumptionItem.quantity'), unit_of_measurement: 'g' }, to: defaultUMWeight }) : 0
      }
    }
  },
  consumptionDollars: {
    headerName: translations.txtConsumptionDollars,
    sortOrder: 1791,
    colId: 'consumptionDollars',
    cellRenderer: 'CellNumber',
    cellClass: ['currency'],
    minWidth: 150,
    keyCreator: params => params.value.input_value,
    wiskGetBottomAggregationValue: rowData => (rowData && rowData.consumptionItem && rowData.consumptionItem.consumption_dollars) || 0,
    aggFunc: 'wiskSum',
    cellRendererParams: {
      decimals: 2,
      prefix: window.WiskGlobals.currency,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      group: !!params.node.group,
      input_value: get(params, 'data.consumptionItem.consumption_dollars', 0)
    })
  }
})

const getMovementsGridColumns = ({ translations, setGlobalAction, closeMovementsForItem, currentPermissionsByType }) => [
  {
    headerName: translations.txtGenericDate,
    colId: 'date',
    field: 'date',
    sort: 'desc',
    cellRendererParams: { useValueFormatter: true },
    valueFormatter: params => formatDate(params.value)
  },
  {
    headerName: translations.txtGenericOperation,
    colId: 'operation',
    minWidth: 100,
    width: 100,
    maxWidth: 200,
    valueGetter: params => {
      if (params.data && params.data.operation) {
        return translations[params.data.operation.type] || params.data.operation.type
      }
      return ''
    }
  },
  {
    headerName: translations.txtGenericDescription,
    colId: 'description',
    minWidth: 100,
    width: 400,
    maxWidth: 600,
    valueGetter: params => (params.data && params.data.description) || ''
  },
  {
    headerName: translations.txtGenericUnits,
    sortOrder: 1770,
    colId: 'units',
    cellClass: ['text-end', 'pt-2', 'pe-2'],
    valueGetter: params => (params.data && params.data.units) || 0
  },
  {
    colId: 'more',
    sortOrder: 2700,
    headerName: translations.columnDetails,
    minWidth: 100,
    width: 100,
    hide: !currentPermissionsByType.invoice_manage,
    cellClass: ['cell-more-details'],
    cellRenderer: 'CellMoreDetails',
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRendererParams: {
      excludeFromExport: true,
      onClick: data => {
        setGlobalAction({ type: 'movementEdit', action: { id: data.id, itemIdFilter: data.item_id, onChange: closeMovementsForItem } })
      }
    },
    valueGetter: params => ({
      id: params.data,
      group: !!params.node.group
    })
  }
]

const getByLocationColumns = ({ translations, showMovements, openInventory }) => [
  {
    headerName: translations.txtGenericLocation,
    colId: 'location',
    valueGetter: params => (params.data && params.data.location && params.data.location.title) || ''
  },
  {
    headerName: translations.txtConsumptionInventoryFrom,
    sortOrder: 1750,
    colId: 'fromInventoryUnits',
    cellRenderer: 'CellNumber',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtonsPosition: 'left',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            openInventory(data.rowData.from.id, id, data.rowData.item_id)
          },
          getVisible: data => data && data.from && data.from.units,
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    wiskGetBottomAggregationValue: rowData => rowData.from.units,
    valueGetter: params => ({
      id: params.data && params.data.location && params.data.location.id,
      rowData: params.data || {},
      group: !!params.node.group,
      input_value: get(params, 'data.from.units', 0)
    })
  },
  {
    colId: 'movements',
    headerName: translations.txtGenericDeliveries,
    sortOrder: 1755,
    cellClass: ['pe-5'],
    cellRenderer: 'CellNumber',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtonsPosition: 'left',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            showMovements(data.rowData)
          },
          getVisible: data => data && data.movements && data.movements.details && data.movements.details.length,
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    wiskGetBottomAggregationValue: rowData => get(rowData, 'movements.units', 0),
    valueGetter: params => ({
      id: params.data && params.data.location && params.data.location.id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.movements.units', 0)
    })
  },
  {
    headerName: translations.txtConsumptionInventoryTo,
    sortOrder: 1770,
    colId: 'toInventoryUnits',
    cellRenderer: 'CellNumber',
    cellRendererParams: {
      infoComponentType: 'extraButtons',
      extraButtonsPosition: 'left',
      extraButtons: [
        {
          id: 1,
          action: (id, data) => {
            openInventory(data.rowData.to.id, id, data.rowData.item_id)
          },
          getVisible: data => data && data.to && data.to.units,
          icon: 'wisk-arrow-right-circle'
        }
      ],
      decimals: 2,
      readonly: true
    },
    wiskGetBottomAggregationValue: rowData => rowData.to.units,
    valueGetter: params => ({
      id: params.data && params.data.location && params.data.location.id,
      group: !!params.node.group,
      rowData: params.data || {},
      input_value: get(params, 'data.to.units', 0)
    })
  },
  {
    headerName: translations.txtConsumptionBottles,
    sortOrder: 1780,
    colId: 'consumptionBottles',
    cellClass: ['text-end', 'pt-2', 'pe-2'],
    valueFormatter: params => toFixedHideZero(params.value, 2),
    wiskGetBottomAggregationValue: rowData => rowData.consumption_units,
    valueGetter: params => get(params, 'data.consumption_units', 0)
  },
  {
    headerName: translations.txtConsumptionDollars,
    sortOrder: 1791,
    colId: 'consumptionDollars',
    cellClass: ['text-end', 'pt-2', 'pe-2', 'currency'],
    cellRendererParams: { decimals: 2 },
    valueFormatter: params => currencyFormatHideZero(params.value),
    wiskGetBottomAggregationValue: rowData => rowData.consumption_dollars,
    valueGetter: params => (params.data && params.data.consumption_dollars) || 0
  }
]
export { gridOptions, getItemsGridColumns, getMovementsGridColumns, getByLocationColumns }
