import { DateTime } from 'luxon'
import merge from 'lodash.merge'
import get from 'lodash.get'
import isString from 'lodash.isstring'
import deburr from 'lodash.deburr'
import isFinite from 'lodash.isfinite'
import escapeRegExp from 'lodash.escaperegexp'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import stringToColor from 'string-to-color'
import chroma from 'chroma-js'
import { getPreferences } from '@/modules/storage'

const conversionRates = {
  g: 1,
  ml: 1,
  L: 1000,
  kg: 1000,
  l: 1000,
  lb: 453.592,
  oz: 29.5735, // Fluid ounces used as 'oz' for volume, correct is 'fl oz' but we need to keep it as oz for compatibility
  dry_oz: 28.3495, // Dry ounces should be oz but we need to keep it as dry_oz for compatibility
  gal: 3785.41,
  pt: 473.176,
  qt: 946.353,
  unit: 1,
  each: 1,
  ea: 1
}

const getMeasurementType = measurement => {
  if (measurement && measurement.unit_of_measurement) {
    if (['g', 'kg', 'lb', 'dry_oz'].includes(measurement.unit_of_measurement)) {
      return 'weight'
    }
    if (['ml', 'L', 'l', 'gal', 'pt', 'qt', 'oz', 'fl_oz'].includes(measurement.unit_of_measurement)) {
      return 'volume'
    }
  }

  return 'unit'
}

const getBaseValueFromMeasurement = measurement => {
  if (measurement?.unit_of_measurement && ['unit', 'each', 'ea'].includes(measurement.unit_of_measurement)) {
    return measurement.quantity
  }
  if (measurement && isFinite(measurement.quantity) && conversionRates[measurement.unit_of_measurement] !== undefined) {
    return measurement.quantity * conversionRates[measurement.unit_of_measurement]
  }
  return 1
}

const convertUM = ({ from, to }) => {
  if (from && to && conversionRates[to] !== undefined) {
    const baseValue = getBaseValueFromMeasurement(from)
    return baseValue / conversionRates[to]
  }
  return 1
}

const getMeasurementShortName = um => window.WiskGlobals?.measurementsById?.[um]?.short || um

const getMeasurementDisplayString = measurement => `${measurement.quantity} ${getMeasurementShortName(measurement.unit_of_measurement)}`

const replaceAccentsAndLowerCase = s => (s && s.toLowerCase && deburr(s.toLowerCase())) || ''

const getLeafNodesRecursive = (node, leafRows = [], found = {}) => {
  if (node && node.group && node.childrenAfterFilter && node.childrenAfterFilter.length) {
    node.childrenAfterFilter.forEach(inner => {
      getLeafNodesRecursive(inner, leafRows, found)
    })
  } else if (node?.data && !found[node.id]) {
    leafRows.push(node)
    found[node.id] = true
  }

  return leafRows
}

//From https://stackoverflow.com/a/38616965/539895
/**
 * Merges objects found in both arrays and returns an array of merged objects.
 * @param {Array} arr1 First array to be merged
 * @param {Array} arr2 Second array to be merged
 */

const mergeArrayOfObjects = (arr1 = [], arr2 = [], key = '') => {
  let merged = [
    ...arr1
      .concat(arr2)
      .reduce((map, obj) => {
        const existingObj = map.get(obj[key])

        if (existingObj) {
          map.set(obj[key], merge({}, existingObj, obj))
        } else {
          map.set(obj[key], merge({}, obj))
        }
        return map
      }, new Map())
      .values()
  ]
  return merged
}

//From http://stackoverflow.com/a/29101013/539895
const round = (value, decimals = 0) => {
  const result = Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
  if (Number.isNaN(result)) {
    return 0
  }
  return result
}

//TODO: pass params in an object
//https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
const formatDate = (date, options = {}) => {
  const { format = 'ff', defaultIfEmpty = '', forceUTC = false, toRelative = false } = options
  if (date && (typeof date === 'number' || typeof date === 'string')) {
    date = new Date(date)
  }
  let parsed = (date && DateTime.fromJSDate(date)) || null

  if (forceUTC && parsed) {
    parsed.setZone('UTC')
  }

  //If user is Wisk and the venue has a timezone set, this will return the date in the venue's timezone
  if (window?.WiskGlobals?.timezoneDirty && !window?.WiskGlobals.alreadyWarnedAboutTimezoneChange) {
    console.warn('!!!The timezone is changed to the venue timezone!!!')
    window.WiskGlobals.alreadyWarnedAboutTimezoneChange = true
  }

  if (toRelative && parsed) {
    return parsed.toRelative()
  }

  return (parsed && parsed.toFormat(format)) || defaultIfEmpty
}

const formatMinutesToHM = minutes => {
  let hoursBase = minutes / 60,
    hours = parseInt(hoursBase, 10),
    remainingMinutes = (hoursBase - hours) * 60

  return `${hours}h : ${round(remainingMinutes)}m`
}

const formatNumber = (value, options = {}) => {
  const { decimals = 0, minDecimals = 0, decimalSeparator = '.', thousandsSeparator = ',', decimalsAsNeeded = false, defaultIfEmpty = '', formatZero = false } = options
  value = round(value, decimals)

  let formatted = defaultIfEmpty
  if (value || (value === 0 && formatZero)) {
    let negative = value < 0,
      leftSideBase = parseInt(value, 10),
      leftSide = leftSideBase.toString().replace(/-/g, ''),
      rightSide = value.toString().split('.')[1] //maybe split on decimalSeparator here instead of the .
    for (let i = leftSide.length - 3; i > 0; i -= 3) {
      leftSide = leftSide.substr(0, i) + thousandsSeparator + leftSide.substr(i)
    }
    leftSide = leftSide || '0'
    formatted = leftSide
    if (rightSide) {
      formatted = leftSide + decimalSeparator + rightSide
      let length = rightSide.toString().length,
        decimalsToAdd = minDecimals || decimals

      if (decimalsToAdd > length && (!decimalsAsNeeded || minDecimals)) {
        for (let i = 0; i < decimalsToAdd - length; i++) {
          formatted += '0'
        }
      }
    } else if (decimals && (!decimalsAsNeeded || minDecimals)) {
      formatted += decimalSeparator
      let decimalsToAdd = minDecimals || decimals
      for (let i = 0; i < decimalsToAdd; i++) {
        formatted += '0'
      }
    }

    formatted = formatted.replace(/-/g, '')
    if (negative) {
      formatted = '-' + formatted
    }
  }

  return formatted
}

const getRandom = () => Math.floor(Math.random() * new Date().getTime())

const guid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0xff) >> 4,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

/**
 * Transform an array of objects into an object. The keys will be created based on the id param.
 * @param {Array} arr The input array Ex.: [{a: 1, b: 2}]
 * @param {String} id Property present on all the objects inside the array. Must be unique and usable as a object key.
 */
const arrayToObjectById = (arr, id = 'id') => {
  let obj = {}

  if (Array.isArray(arr) && arr.length) {
    arr.forEach(item => {
      if (item && id && get(item, id)) {
        obj[get(item, id)] = item
      } else {
        console.warn('******* arrayToObjectById item or item[id] falsy', item, id)
      }
    })
  }
  return obj
}

const valuesToObjectById = (arr, idKey = 'id', valueKey = 'value') => {
  let obj = {}

  if (Array.isArray(arr)) {
    arr.forEach(item => {
      if (idKey && item[idKey]) {
        obj[item[idKey]] = item[valueKey]
      }
    })
  }

  return obj
}

const prepareItemsForWiskInput = (arr = [], pretty = false) => arr.filter(i => !!i).map(i => ({ title: pretty ? (i[0].toUpperCase() + i.slice(1)).replace(/_/g, ' ') : i, id: i }))

const isValidDate = date => date && Object.prototype.toString.call(date) === '[object Date]' && date.getTime && date.getTime()

const isValidEmail = check => {
  let emailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailTest.test(check)
}
const isValidURL = check => {
  let urlTest = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gim
  return !check || urlTest.test(check)
}

const isValidPhoneNumber = value => {
  let stripped = (value || '')
    .toString()
    .replace(new RegExp(escapeRegExp('+'), 'g'), '')
    .replace(new RegExp(escapeRegExp('-'), 'g'), '')
    .replace(new RegExp(escapeRegExp(' '), 'g'), '')
  return stripped.length > 6 && !!parseInt(stripped, 10)
}

const getURLParam = name => decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || ['', ''])[1].replace(/\+/g, '%20')) || ''

const toFixed = (value, decimals, defaultIfNotNumber = '-') => (isFinite(value) ? value.toFixed(decimals) : defaultIfNotNumber)

const toFixedHideZero = (value, decimals) => (isFinite(value) && value ? value.toFixed(decimals) : '')

const percentageFormat = (number, digits = 2, addPercentSymbol = true, defaultIfEmpty = '-') => {
  let result

  digits = parseInt(digits, 10)

  number = (number && number.input_value) || number
  // ** operator info here https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Exponentiation
  if (isFinite(number)) {
    result = Math.round(number * 10 ** digits * 100) / 10 ** digits + (addPercentSymbol ? '%' : '')
  } else {
    result = defaultIfEmpty
  }

  return result
}

const cloudImage = (image, format) => {
  let defaultImage = '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'
  if (!image) {
    image = defaultImage
  }
  if (image.startsWith('http')) {
    return image
  }

  if (image.endsWith('.pdf')) {
    console.warn('PDF in cloudImage !!!!!!!!!!!!!!!!')
  }

  const s3Url = `https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/${image}`
  if (format === 'original' || image.endsWith('.pdf')) {
    return s3Url
  }

  let sizes = {
    huge: '1500x2400',
    hugeLandscape: '2400x1500',
    xLarge: '1300x990',
    large: '500x800',
    mdLarge: '300x480',
    mdLargeLandscape: '480x300',
    mdSmall: '200x320',
    small: '120x160',
    smallLandscape: '160x120',
    thumbLandscape: '70x49',
    thumb: '49x70',
    pdfThumb: '92x120',
    tinySquare: '28x28',
    tinyThumb: '28x40',
    tinyThumbLandscape: '32x22'
  }

  if (!format || !sizes[format]) {
    format = 'thumb'
  }

  return `https://images.wisk.ai/unsafe/fit-in/${sizes[format]}/filters:format(webp):fill(transparent)/${encodeURIComponent(s3Url)}`
}

const unitsCases = (value, orderFormat, caseSize) => {
  if (!value) {
    return ''
  }

  let toReturn = ''
  let units = Math.round(value * 10) / 10
  toReturn = units === 1 ? ' unit' : ' units'
  if (orderFormat === 'case' && caseSize > 1) {
    let cases = parseFloat(value / caseSize)
    cases = Math.round(cases * 10) / 10
    toReturn += ` (${cases} cases)`
  }
  return toReturn
}

const closestParent = (element, selector) => {
  let ep = Element.prototype
  ep.matches = ep.matches || ep.webkitMatchesSelector || ep.msMatchesSelector || ep.mozMatchesSelector

  while (element && element !== document.body) {
    element = element.parentElement
    if (element && element.matches(selector)) return element
  }
  return null
}

const getTextWidthInPixels = (text, font = '16px serif') => {
  let canvas = getTextWidthInPixels.canvas || (getTextWidthInPixels.canvas = document.createElement('canvas'))
  let context = canvas.getContext('2d')
  context.font = font
  let metrics = context.measureText(text)
  return metrics.width
}

const getPDFRegionPosition = (region, scale, rotation) => {
  let style = {}

  switch (rotation) {
    case 90:
      style.height = round((region.xmax - region.xmin) / scale, 2) + 'px'
      style.width = round((region.ymax - region.ymin) / scale, 2) + 'px'
      style.top = round(region.xmin / scale, 2) + 'px'
      style.right = round(region.ymin / scale, 2) + 'px'
      break
    case 180:
      style.width = round((region.xmax - region.xmin) / scale, 2) + 'px'
      style.height = round((region.ymax - region.ymin) / scale, 2) + 'px'
      style.bottom = round(region.ymin / scale, 2) + 'px'
      style.right = round(region.xmin / scale, 2) + 'px'
      break
    case 270:
      style.height = round((region.xmax - region.xmin) / scale, 2) + 'px'
      style.width = round((region.ymax - region.ymin) / scale, 2) + 'px'
      style.bottom = round(region.xmin / scale, 2) + 'px'
      style.left = round(region.ymin / scale, 2) + 'px'
      break
    default:
      style.width = round((region.xmax - region.xmin) / scale, 2) + 'px'
      style.height = round((region.ymax - region.ymin) / scale, 2) + 'px'
      style.top = round(region.ymin / scale, 2) + 'px'
      style.left = round(region.xmin / scale, 2) + 'px'
      break
  }

  return style
}

const getImageSize = imgSrc =>
  new Promise(resolve => {
    let img = new Image()

    img.onload = () => {
      resolve({ height: img.height, width: img.width })
    }

    img.src = imgSrc
  })

const currencyFormat = (value, currency = get(window, 'WiskGlobals.currency', '$'), decimals = 2, decimalsAsNeeded = false) => `${currency}${formatNumber(value, { decimals, formatZero: true, decimalsAsNeeded })}`

const currencyFormatForExport = ({ currency = get(window, 'WiskGlobals.currency', '$'), decimals = 2 }) => ({
  numberFormat: {
    format: `${currency}#,##0${decimals ? '.' + '0'.repeat(decimals) : ''}`
  }
})

const currencyFormatHideZero = (value, currency, decimals, decimalsAsNeeded) => {
  if (isFinite(value) && value) {
    return currencyFormat(value, currency, decimals, decimalsAsNeeded)
  }
  if (value?.input_value && isFinite(value.input_value)) {
    return currencyFormat(value.input_value, currency, decimals, decimalsAsNeeded)
  }
  return ''
}

const currencyFormatForPlanSelector = (value, { currency, placeholder, long = 'hide', decimals = 2, minDecimals = 2 }) => {
  if (value && currency) {
    let localLongPosition = null,
      formatted = ''

    if (long !== 'hide') {
      localLongPosition = long || currency.position
    }

    if (localLongPosition === 'left' && currency.code) {
      formatted += `${currency.code.toUpperCase()} `
    }
    if (currency.position === 'left') {
      formatted += currency.symbol
    }

    formatted += `${formatNumber(value, { decimals, minDecimals })}`

    if (currency.position === 'right') {
      formatted += currency.symbol
    }
    if (localLongPosition === 'right' && currency.code) {
      formatted += ` ${currency.code.toUpperCase()}`
    }
    return formatted
  }
  return placeholder || ''
}

const isTouch = () => {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}

const compareNumbers = (x = 0, y = 0) => {
  // if (isFinite(x) || isFinite(y)) {
  //   console.log('x, y', x, y)
  // }
  if (x < y) {
    return -1
  }
  if (x > y) {
    return 1
  }

  return 0
}

const compareStrings = (x = '', y = '') => {
  x = isString(x) && x ? x.toLowerCase() : x
  y = isString(y) && y ? y.toLowerCase() : y

  return compareNumbers(x, y)
}

const fillRange = (start, end) => [...Array(end - start + 1)].map((item, index) => start + index)

const getStringForSearchRecursive = ({ payload, stopAtLevel = 2, currentLevel = 0, propertiesToExclude = [] }) => {
  let result = []

  if (payload) {
    if (typeof payload === 'string' || typeof payload === 'number') {
      result.push(payload)
    } else if (currentLevel <= stopAtLevel) {
      Object.entries(payload).forEach(([key, value]) => {
        if (!propertiesToExclude.includes(key)) {
          result.push(getStringForSearchRecursive({ payload: value, stopAtLevel, currentLevel: currentLevel + 1, propertiesToExclude }))
        }
      })
    }
  }

  return result.join(' ')
}

const stringFilter = (filter = '', value = '', filterText = '') => {
  let filterTextLoweCase = replaceAccentsAndLowerCase(filterText).trim(),
    valueLowerCase = replaceAccentsAndLowerCase(value.toString()).trim()

  switch (filter) {
    case 'contains': {
      return (
        !filterTextLoweCase ||
        filterTextLoweCase
          .split(' ')
          .filter(filterWord => !!filterWord.trim())
          .every(filterWord => filterWord && valueLowerCase.includes(filterWord))
      )
    }
    case 'notContains':
      return !valueLowerCase.includes(filterTextLoweCase)
    case 'equals':
      return valueLowerCase === filterTextLoweCase
    case 'notEqual':
      return valueLowerCase !== filterTextLoweCase
    case 'startsWith':
      return valueLowerCase.startsWith(filterTextLoweCase)
    case 'endsWith': {
      return valueLowerCase.endsWith(filterTextLoweCase)
    }
    default:
      // should never happen
      console.warn(`invalid filter type ${filter}`)
      return false
  }
}

const objectFilter = ({ filter = 'contains', payload = {}, query = '', stopAtLevel = 4, propertiesToExclude = [] }) =>
  stringFilter(filter, getStringForSearchRecursive({ payload, stopAtLevel, propertiesToExclude }), query)

const loadScript = (url, options = {}) => {
  const { async = false, defer = false, id = '', preparator, callback, target, customAttributes } = options
  if (id) {
    let oldScript = document.getElementById(id)
    if (oldScript) {
      oldScript.remove()
    }
  }
  if (typeof preparator === 'function') {
    preparator()
  }
  let script = document.createElement('script')
  script.async = async
  script.defer = defer
  script.id = id || ''
  script.src = url
  if (typeof callback === 'function') {
    script.onload = callback
  }
  if (customAttributes) {
    Object.keys(customAttributes).forEach(cp => {
      script.setAttribute(cp, customAttributes[cp])
    })
  }
  if (target) {
    if (typeof target === 'function') {
      const element = target()
      element.appendChild(script)
    } else {
      target.appendChild(script)
    }
  } else {
    document.body.appendChild(script)
  }
}

const wiskCaseUnit = (value, caseSize) => {
  if (value) {
    if (caseSize === 1) {
      const units = round(value, 1)
      return value === 1 ? `${units} unit` : `${units} units`
    }
    let cases = parseInt(value / caseSize, 10),
      units = round(value - cases * caseSize, 1),
      unitsResult = value === 1 ? `${units} unit` : `${units} units`,
      casesResult = cases === 1 ? `${cases} case` : `${cases} cases`

    return `${casesResult}, ${unitsResult}`
  }

  return ''
}

const getPOSItemIngredientDescription = ingredient => {
  if (ingredient && (ingredient.item || ingredient.subrecipe) && ingredient.serving_size) {
    let item = ingredient.item?.item_id ? ingredient.item : ingredient.subrecipe,
      description

    if (ingredient.serving_size?.type === 'predefined') {
      description = `${ingredient.serving_size.title} of ${item.title} (${ingredient.serving_size.quantity} ${ingredient.serving_size.unit_of_measurement})`
    } else {
      description = `${toFixed(ingredient.serving_size.quantity, 2)} ${ingredient.serving_size.unit_of_measurement} of ${item?.title}`
    }

    let cost = currencyFormatHideZero(get(ingredient, 'stats.cost.dollars', 0), window.WiskGlobals.currency, 2)

    if (cost) {
      return `${description} (${cost})`
    }

    return description
  }
  return '-'
}

//https://stackoverflow.com/a/45462325/539895
const sortObject = obj =>
  Object.keys(obj)
    .sort()
    .reduce((a, v) => {
      a[v] = obj[v]
      return a
    }, {})

const fromJSON = json => {
  try {
    let data = JSON.parse(json)
    return data
  } catch (error) {
    return null
  }
}

const addPlanFeatureAdActionsToSidebar = (navConfig, featuresByType) => {
  if (navConfig) {
    const checkNavItem = navItem => {
      if (navItem && navItem.subscriptionPlanFeature && featuresByType[navItem.subscriptionPlanFeature] && !featuresByType[navItem.subscriptionPlanFeature].enabled) {
        let urlBackup = navItem.url
        delete navItem.url
        navItem.action = { type: 'planFeatureAd', action: { feature: navItem.subscriptionPlanFeature, subFeature: navItem.subscriptionPlanSubFeature, urlBackup } }
      } else if (Array.isArray(navItem.children)) {
        navItem.children = navItem.children.map(checkNavItem)
      }
      return navItem
    }

    if (Array.isArray(navConfig.bottom)) {
      navConfig.bottom = navConfig.bottom.map(checkNavItem)
    }
    if (Array.isArray(navConfig.top)) {
      navConfig.top = navConfig.top.map(checkNavItem)
    }
  }
  return navConfig
}

const checkUserViewPermissions = (navConfig, user, venue, pageViewPermissions) => {
  if (user && venue && pageViewPermissions) {
    let nav = merge({}, navConfig),
      userItem = nav.bottom.find(item => item.id === 'user')

    if (userItem) {
      userItem.name = user.full_name
      // userItem.extra = venue.title

      if (Array.isArray(userItem.children)) {
        let children = []

        userItem.children.forEach(child => {
          if (pageViewPermissions.userItems && pageViewPermissions[child.id]) {
            if (child.id === 'venue_settings' && child.action && child.action.action) {
              child.action.action.venue = venue
            }

            children.push(child)
          }
        })

        userItem.children = children
      }
    }

    if (Array.isArray(nav.top) && !pageViewPermissions.all) {
      let top = []
      nav.top.forEach(element => {
        let children = []
        if (pageViewPermissions.top && pageViewPermissions[element.id]) {
          if (Array.isArray(element.children)) {
            element.children.forEach(child => {
              if (pageViewPermissions[child.id]) {
                children.push(child)
              }
            })
            element.children = children
          }

          if (!element.children || element.children.length) {
            top.push(element)
          }
        }
      })
      nav.top = top
    }
    return nav
  }
  return {}
}

const setupCustomFieldsAsColumns = ({ customFields, translate, save, agGridFilters, columns = [] }) => {
  let activeFields = customFields.filter(f => !f.archived),
    customFieldColumns = [],
    columnHeaders = columns.map(c => c.headerName)

  if (activeFields && activeFields.length) {
    for (let i = 0; i < activeFields.length; i++) {
      let field = activeFields[i],
        column = {
          headerName: `${field.label}${columnHeaders.includes(field.label) ? ' (' + translate('txtCustomField') + ')' : ''}`,
          keyCreator: params => get(params, 'value.input_value.title', get(params, 'value.input_value', '')) || `(${translate('txtGenericNoValue')})`,
          minWidth: 150,
          hide: true,
          maxWidth: 200,
          colId: `custom-field-${field.id}`,
          sortOrder: 30000 + i,
          cellRendererParams: {
            customFieldColumn: true,
            translate,
            multiline: field.inputTypeAttrs.multiline,
            updateValue: params => {
              let wrapper = { ...field.inputTypeAttrs.customFieldValueWrapper }
              wrapper.value.value = params.value.id ? params.value.id : params.value
              save({ ...params, value: wrapper })
            },
            type: field.inputTypeAttrs.operation
          },
          cellClass: field.type_definition.type === 'currency' ? ['currency custom-field-cell'] : ['custom-field-cell'],
          headerClass: ['custom-field-header'],
          headerTooltip: translate('txtCustomField') + ' - ' + field.label,
          valueGetter: params => {
            let customFieldValues = (params.data && Array.isArray(params.data.custom_fields) && valuesToObjectById(params.data.custom_fields.map(z => ({ id: z.id, value: z.value.value })))) || {},
              value = customFieldValues[field.id]

            if (field.inputTypeAttrs.inputType === 'checkbox') {
              value = !!value
            }

            if (value && field.type_definition.type === 'dropdown') {
              value = { title: value, id: value }
            }

            return {
              id: get(params, 'data.item_id', get(params, 'data.id')),
              readonly: !!params?.data?.wiskRowHidden,
              input_value: value
            }
          }
        }

      if (field.inputTypeAttrs && field.inputTypeAttrs.inputType) {
        switch (field.inputTypeAttrs.inputType) {
          case 'checkbox':
            column.cellRenderer = 'CellCheckbox'
            column.enableRowGroup = true
            column.minWidth = 50
            column.maxWidth = 100
            break
          case 'datepicker':
            column.cellRenderer = 'CellText'
            column.keyCreator = params => formatDate(params.value.input_value)
            column = { ...column, ...agGridFilters.date }
            column.cellRendererParams.asDatePicker = true
            break

          case 'number':
            column.cellRenderer = 'CellNumber'
            column.minWidth = 100
            column.maxWidth = 150
            column = { ...column, ...agGridFilters.number }
            column.cellRendererParams.decimals = field.inputTypeAttrs.decimals
            column.cellRendererParams.prefix = field.inputTypeAttrs.prefix
            column.cellRendererParams.suffix = field.inputTypeAttrs.suffix
            column.aggFunc = 'wiskSum'
            break
          case 'images':
          case 'attachments':
            column.cellRenderer = 'CellAttachments'
            break
          case 'text':
            column.cellRenderer = 'CellText'
            column.enableRowGroup = true
            column = { ...column, ...agGridFilters.text }
            break
          case 'wiskSelect':
            column.cellRenderer = 'CellPopMultiselect'
            column.enableRowGroup = field.type_definition.type === 'dropdown'

            column.cellRendererParams.callBeforeAddTag = field.inputTypeAttrs.callBeforeAddTag
            column.cellRendererParams.trackBy = field.type_definition.type === 'multiselect' ? 'uuid' : 'id'
            column.cellRendererParams.multiple = field.type_definition.type === 'multiselect'
            column.cellRendererParams.required = true
            column.cellRendererParams.waitChangeEventUntilClosed = field.type_definition.type === 'multiselect'
            column.cellRendererParams.getItems = () => field.inputTypeAttrs.items
            break
          default:
            break
        }
      }

      customFieldColumns.push(column)
    }
  }
  return customFieldColumns
}

const prepareSubrecipe = (payload, state) => {
  if (payload && payload.id) {
    let measurement = payload.measurement || get(payload, 'yields.measurement'),
      subrecipeCost = state.subrecipeCostById[payload.id] || { ingredients: [] },
      subrecipeCostDollars = get(subrecipeCost, 'stats.cost.dollars', 0),
      subrecipeStats = get(subrecipeCost, 'stats', {}),
      result = {
        ...payload,
        measurement,
        type: 'subrecipe',
        allergens: [...new Set(get(payload, 'depleted_items', []).flatMap(d => (state.bottlesById[d.item_id] || { allergens: [] }).allergens))],
        dollars: subrecipeCostDollars,
        stats: subrecipeStats,
        titleSuffix:
          ` - (${measurement.alias ? measurement.alias + ' - ' : ''}${measurement.quantity} ${measurement.unit_of_measurement}) (batch)` + (payload.archived ? ` - (${state.translations.txtGenericArchived})` : ''),
        ingredients: []
      },
      ingredients = payload.ingredients || []

    if (ingredients.length) {
      ingredients.forEach(ingredient => {
        if (ingredient && (ingredient.item_id || ingredient.subrecipe_id)) {
          let item = (!ingredient.subrecipe_id && state.bottlesById[ingredient.item_id]) || {},
            subrecipe = (ingredient.subrecipe_id &&
              prepareSubrecipe(
                state.subrecipes.find(s => s.id === ingredient.subrecipe_id),
                state
              )) || { yields: {} },
            image = item.image || subrecipe.image,
            operation = { value: { item_id: item.item_id, subrecipe_id: ingredient.subrecipe_id, id: ingredient.id }, type: ingredient.subrecipe_id ? 'subrecipe' : 'item' },
            id = ingredient.id,
            ingredientCost = subrecipeCost.ingredients.find(i => i && i.type === ingredient.type && i.id === id),
            ingredientDollars = get(ingredientCost, 'stats.cost.dollars', 0),
            ingredientStats = get(ingredientCost, 'stats', {})

          result.ingredients.push({
            id,
            gridId: id,
            item,
            item_distributor_ids: item.item_distributor_ids || [],
            allergens: item.allergens || subrecipe.allergens || [],
            subrecipe,
            operation,
            custom_fields: item.custom_fields,
            dollars: ingredientDollars,
            stats: ingredientStats,
            item_id: ingredient.item_id,
            subrecipe_id: ingredient.subrecipe_id,
            type: ingredient.subrecipe_id ? 'subrecipe' : 'item',
            image,
            title: item.title || subrecipe.title,
            titleSuffix: item.titleSuffix || subrecipe.titleSuffix,
            measurement: item.measurement || get(subrecipe, 'yields.measurement'),
            archived: item.archived || subrecipe.archived,
            serving_size: ingredient.serving_size
          })
        }
      })
    }

    return result
  }
  return payload
}

const preparePOSItem = (payload, state, showAllIngredients) => {
  if (payload && payload.id) {
    let posItemCost = state.posItemCostsById[payload.id] || { ingredients: [] },
      posItemCostDollars = get(posItemCost, 'stats.cost.dollars', 0),
      posItemStats = get(posItemCost, 'stats', {}),
      extraData = state.posItemsExtraDataById[payload.id] || {},
      result = {
        ...payload,
        ...extraData,
        title: payload.title + (payload.archived ? ` - (${state.translations.txtGenericArchived})` : ''),
        unmapped: payload.flags && payload.flags.unmapped,
        allergens: [...new Set(get(payload, 'depleted_items', []).flatMap(d => (state.bottlesById[d.item_id] || { allergens: [] }).allergens))],
        cost: posItemCostDollars,
        stats: posItemStats,
        ingredients: []
      },
      ingredients = (showAllIngredients ? payload.depleted_items : payload.ingredients) || []

    if (ingredients.length) {
      ingredients.forEach(ingredient => {
        if (ingredient && (ingredient.item_id || ingredient.subrecipe_id)) {
          let item = (!ingredient.subrecipe_id && state.bottlesById[ingredient.item_id]) || {},
            subrecipe = (ingredient.subrecipe_id &&
              prepareSubrecipe(
                state.subrecipes.find(s => s.id === ingredient.subrecipe_id),
                state
              )) || { yields: {} },
            image = item.image || subrecipe.image,
            price = state.itemPricesById[ingredient.item_id] || {},
            operation = { value: { item_id: item.item_id, subrecipe_id: ingredient.subrecipe_id, id: ingredient.id }, type: ingredient.subrecipe_id ? 'subrecipe' : 'item' },
            id = ingredient.id,
            ingredientCost = showAllIngredients ? posItemCost.depleted_items.find(i => i && i.type === ingredient.type && i.id === id) : posItemCost.ingredients.find(i => i && i.type === ingredient.type && i.id === id),
            ingredientDollars = get(ingredientCost, 'stats.cost.dollars', 0),
            ingredientStats = get(ingredientCost, 'stats', {})

          result.ingredients.push({
            id,
            gridId: ingredient.id,
            item,
            item_distributor_ids: item.item_distributor_ids || [],
            allergens: item.allergens || subrecipe.allergens || [],
            cost: ingredientDollars,
            stats: ingredientStats,
            subrecipe,
            operation,
            image,
            custom_fields: item.custom_fields,
            type: ingredient.subrecipe_id ? 'subrecipe' : 'item',
            price: price.price_per_unit,
            fee: item.fee,
            volume: item.volume,
            item_id: ingredient.item_id,
            subrecipe_id: ingredient.subrecipe_id,
            title: item.title || subrecipe.title,
            titleSuffix: item.titleSuffix || subrecipe.titleSuffix,
            measurement: item.measurement || subrecipe.yields.measurement,
            archived: item.archived || subrecipe.archived,
            serving_size: showAllIngredients ? get(ingredient, 'stats.cost') : ingredient.serving_size || get(ingredient, 'item.serving_size')
          })
        }
      })
    }

    return result
  }
  return payload
}

const prepareAggregatedItem = (b, state = {}) => {
  if (b && b.item_id) {
    let item = {
      ...b,
      titleSuffix:
        ` - (${b.measurement.alias ? b.measurement.alias + ' - ' : ''}${round(b.measurement.quantity, 4)} ${b.measurement.unit_of_measurement})` + (b.archived ? ` - (${state.translations.txtGenericArchived})` : '')
    }

    return item
  }
  return b
}

const prepareVariantAsItem = (variant, state) => {
  if (variant?.id && variant.item_id) {
    let bottle = state.bottlesById[variant.item_id] || { item_distributor_ids: [] }

    bottle.item_distributor_ids = bottle.item_distributor_ids || []

    return {
      ...bottle,
      variant,
      title: bottle.title,
      gl_account_id: variant.gl_account_id,
      tax_rate_id: variant.tax_rate_id,
      titleSuffix:
        (variant.title ? ` (${variant.title})` : '') +
        ` - (${variant.measurement.alias ? variant.measurement.alias + ' - ' : ''}${variant.measurement.quantity} ${variant.measurement.unit_of_measurement})` +
        (bottle.archived ? ` - (${state.translations.txtGenericArchived})` : ''),
      measurement: variant.measurement,
      empty_weights: variant.empty_weights,
      weights: variant.weights,
      inventoriable_as_cases: variant.inventoriable_as_cases,
      case_size: variant.case_size || 1,
      order_format: variant.order_format,
      distributor_id: variant.distributor_id,
      distributor: state.distributorsById[variant.distributor_id] || {},
      item_distributor_id: variant.id,
      distributor_code: variant.item_code,
      price: state.itemVariantPricesById[variant.id]
    }
  }
  return variant
}

const prepareVariantIdAsItem = (variantId, state) => prepareVariantAsItem(state.itemVariantsById[variantId], state)

const prepareScannedInvoice = (scannedInvoice = {}, state = {}) => ({
  ...scannedInvoice,
  distributor: { ...get(scannedInvoice, 'distributor', {}), ...state.distributorsById[get(scannedInvoice, 'distributor.id', 0)] },
  date: scannedInvoice?.date ? new Date(scannedInvoice.date) : null,
  movement_id: scannedInvoice.movement_id || scannedInvoice?.status?.movement_id,
  insert_date: new Date(scannedInvoice.insert_date),
  scans: (scannedInvoice?.scans || []).map(s => (s.endsWith('.pdf') ? 'https://wisk.s3.us-west-2.amazonaws.com/shared/images/bottles/full/' + s : s))
})

const checkElementIsVisibleAfterScroll = (target, id, onVisibilityChange, intersectionObserverOptions = { root: null, threshold: 0.5 }) => {
  if (typeof onVisibilityChange === 'function') {
    const onIntersection = (entries, options) => {
      entries.forEach(entry => {
        let visible = entry.intersectionRatio >= options.thresholds[0]

        onVisibilityChange(id, visible)
      })
    }
    let observer = new IntersectionObserver(onIntersection, intersectionObserverOptions)

    observer.observe(target)

    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect()
      }
    }
  }
  return () => {}
}

//this replaces the lodash set function because it has a vulnerability
const setValueAtPath = (obj, path, value) => {
  if (typeof path === 'string') {
    path = path.split('.')
  }
  if (path.length > 1) {
    const [head, ...rest] = path
    // If the current segment points to a primitive value, replace it with an object
    if (!obj[head] || typeof obj[head] !== 'object' || Array.isArray(obj[head])) {
      obj[head] = {}
    }
    setValueAtPath(obj[head], rest, value)
  } else {
    // This handles setting the value when the path is fully traversed
    obj[path[0]] = value
  }
}

const getIconFromFileURL = url => {
  let fileExtension = url ? url.split('.').pop().toLowerCase() : null

  switch (fileExtension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return { icon: 'file-image', color: '' }
    case 'pdf':
      return { icon: 'wisk-pdf', color: '#f70000' }
    case 'ppt':
    case 'pptx':
      return { icon: 'wisk-powerpoint', color: '#d35230' }
    case 'doc':
    case 'docx':
      return { icon: 'wisk-word', color: '#2b7cd3' }
    case 'xls':
    case 'xlsx':
      return { icon: 'wisk-excel', color: '#107c41' }

    default:
      return { icon: 'wisk-file', color: '' }
  }
}

const getColorFromString = str => {
  const adjustColor = color => {
    // eslint-disable-next-line import/no-named-as-default-member
    let contrast = chroma.contrast(color, 'white')

    while (contrast < 4.5) {
      color = chroma(color).darken().hex()
      // eslint-disable-next-line import/no-named-as-default-member
      contrast = chroma.contrast(color, 'white')
    }
    return color
  }

  let color = stringToColor(str)

  // eslint-disable-next-line import/no-named-as-default-member
  if (chroma.contrast(color, 'white') < 4.5) {
    color = adjustColor(color)
  }
  return color
}

const venueIdFromUrl = () => {
  const regex = /^\/(\d+)/,
    path = window.location.pathname,
    match = path.match(regex)

  if (match) {
    return match[1]
  }

  const urlObj = new URL(window.location.href)
  const params = new URLSearchParams(urlObj.search)
  return params.get('venue_id') || null
}

//to clear the veneue id pass null explicitly
const venueIdToUrl = id => {
  const currentUrl = new URL(window.location.href),
    pathSegments = currentUrl.pathname.split('/').filter(segment => segment !== ''),
    currentPath = '/' + pathSegments.join('/'),
    excludedPaths = [
      '/deep-link',
      '/auth',
      '/signin',
      '/signup',
      '/logout',
      '/testpage',
      '/analytics',
      '/components-demo',
      '/translations',
      '/public/orders',
      '/public/lightspeed/signup',
      '/public/signup/lightspeed',
      '/public/redirect',
      '/public/no_credit_card',
      '/public/link_to_email',
      '/public/dashboard/printable'
    ],
    shouldExcludePath = excludedPaths.find(excludedPath => currentPath.startsWith(excludedPath))

  if (id === null && pathSegments.length > 0 && /^\d+$/.test(pathSegments[0])) {
    pathSegments.shift()
  }

  if (id && !shouldExcludePath) {
    if (pathSegments.length > 0 && /^\d+$/.test(pathSegments[0])) {
      pathSegments[0] = id
    } else {
      pathSegments.unshift(id)
    }
  }

  const newPathname = '/' + pathSegments.join('/')
  currentUrl.pathname = newPathname

  window.history.replaceState(window.history.state, '', currentUrl.href)
}

const loadPDF = url =>
  new Promise((resolve, reject) => {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js?v=${window.wiskWebAppCOMMITHASH}`

    if (url) {
      pdfjsLib
        .getDocument(url)
        .promise.then(pdf => {
          console.log('pdf', pdf)
          resolve(pdf)
        })
        .catch(error => {
          if (error instanceof pdfjsLib.RenderingCancelledException) {
            console.log('Page rendering was cancelled')
            reject(new Error('RenderingCancelled'))
          } else if (error instanceof pdfjsLib.InvalidPDFException) {
            console.log('Invalid PDF')
            reject(new Error('InvalidPDF'))
          } else {
            reject(error)
          }
        })
    }
  })

const promptDownload = ({ url, fileName, blob }) => {
  url = url || window.URL.createObjectURL(blob)

  let a = document.createElement('a')

  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()

  setTimeout(() => {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

const downloadObjectAsJson = (data, fileName = 'file.json') => {
  let blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' })
  promptDownload({ blob, fileName })
}

const cleanNonPrintableCharacters = input => {
  // Create a regex dynamically to match control characters to make eslint happy
  const controlChars = new RegExp('[' + String.fromCharCode(0x00) + '-' + String.fromCharCode(0x1f) + String.fromCharCode(0x7f) + ']', 'g')
  return input.replace(controlChars, '')
}

// talking about an array with 150,000 large objects
// target.push(...data) will cause stack overflow
const pushLargeArray = (target, data, chunkSize = 50000) => {
  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    Array.prototype.push.apply(target, chunk)
  }
}

const emptyBottle = {
  title: '',
  item_id: null,
  image: null,
  volume: null,
  barcodes: null,
  degrees: null,
  case_size: null,
  distributor_id: null,
  distributor_code: null,
  price: null,
  retail_price: null,
  fee: null,
  par_level: null,
  order_format: null,
  weights: null,
  empty_weights: null,
  bottle_id: null
}

// eslint-disable-next-line no-promise-executor-return
const setTimeoutAsync = ms => new Promise(resolve => setTimeout(resolve, ms))

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.startsWith('192.168.')

const getEnvironmentInfo = () => {
  const environments = { 'web.wisk.cool': 1, 'web.wisk.dev': 2 },
    environment = (isLocalhost ? getPreferences('environment') : environments[window.location.host]) || 0

  return { 1: { title: 'Stage', color: '#99cec1' }, 2: { title: 'Dev', color: '#ffea9d' } }[environment]
}

const getDateRangeShortcuts = (venueBusinessDayStartHour = 6) => ({
  today: [DateTime.fromJSDate(new Date()).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate(), new Date()],
  yesterday: [
    DateTime.fromJSDate(new Date()).minus({ days: 1 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ],
  lastWeek: [
    DateTime.fromJSDate(new Date()).minus({ days: 7 }).startOf('week').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).minus({ days: 7 }).endOf('week').plus({ days: 1 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ],
  last7Days: [DateTime.fromJSDate(new Date()).minus({ days: 7 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate(), new Date()],
  last30Days: [DateTime.fromJSDate(new Date()).minus({ days: 30 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate(), new Date()],
  currentMonth: [DateTime.fromJSDate(new Date()).startOf('month').plus({ hours: venueBusinessDayStartHour }).toJSDate(), new Date()],
  lastMonth: [
    DateTime.fromJSDate(new Date()).startOf('month').minus({ days: 2 }).startOf('month').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).startOf('month').minus({ days: 2 }).endOf('month').plus({ days: 1 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ],
  currentQuarter: [
    DateTime.fromJSDate(new Date()).startOf('quarter').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).endOf('quarter').plus({ days: 1 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ],
  lastQuarter: [
    DateTime.fromJSDate(new Date()).minus({ months: 3 }).startOf('quarter').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).minus({ months: 3 }).endOf('quarter').plus({ days: 1 }).startOf('day').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ],
  currentYear: [DateTime.fromJSDate(new Date()).startOf('year').plus({ hours: venueBusinessDayStartHour }).toJSDate(), new Date()],
  lastYear: [
    DateTime.fromJSDate(new Date()).minus({ years: 1 }).startOf('year').plus({ hours: venueBusinessDayStartHour }).toJSDate(),
    DateTime.fromJSDate(new Date()).minus({ years: 1 }).endOf('year').plus({ hours: venueBusinessDayStartHour }).toJSDate()
  ]
})

export {
  addPlanFeatureAdActionsToSidebar,
  arrayToObjectById,
  checkElementIsVisibleAfterScroll,
  checkUserViewPermissions,
  cleanNonPrintableCharacters,
  closestParent,
  cloudImage,
  compareNumbers,
  compareStrings,
  convertUM,
  currencyFormat,
  currencyFormatForPlanSelector,
  currencyFormatHideZero,
  currencyFormatForExport,
  downloadObjectAsJson,
  emptyBottle,
  fillRange,
  formatDate,
  formatMinutesToHM,
  formatNumber,
  fromJSON,
  getBaseValueFromMeasurement,
  getColorFromString,
  getDateRangeShortcuts,
  getEnvironmentInfo,
  getIconFromFileURL,
  getImageSize,
  getLeafNodesRecursive,
  getMeasurementType,
  getMeasurementDisplayString,
  getPDFRegionPosition,
  getPOSItemIngredientDescription,
  getRandom,
  getMeasurementShortName,
  getStringForSearchRecursive,
  getTextWidthInPixels,
  getURLParam,
  guid,
  isTouch,
  isValidDate,
  isValidEmail,
  isValidPhoneNumber,
  isValidURL,
  loadPDF,
  loadScript,
  mergeArrayOfObjects,
  objectFilter,
  percentageFormat,
  prepareAggregatedItem,
  prepareItemsForWiskInput,
  preparePOSItem,
  prepareScannedInvoice,
  prepareSubrecipe,
  prepareVariantAsItem,
  prepareVariantIdAsItem,
  promptDownload,
  replaceAccentsAndLowerCase,
  round,
  setTimeoutAsync,
  setupCustomFieldsAsColumns,
  setValueAtPath,
  sortObject,
  stringFilter,
  toFixed,
  toFixedHideZero,
  unitsCases,
  valuesToObjectById,
  venueIdFromUrl,
  venueIdToUrl,
  wiskCaseUnit,
  pushLargeArray,
  isLocalhost
}
