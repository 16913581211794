<template>
  <wiskInput infoTooltipKey="b4bb418d-da11-440e-9f0c-15a87cc0645d" fitChildren :label="translations.txtVenueOperationsInfo" :modelValue="venueInfoSummary" showPlainText disabled
    v-if="venue && venue.id" :fitChildrenCols="3" class="light-control">

    <b-button type="button" variant="link" class="float-end h-100" size="sm" @click="modalVisible = true">
      <icon name="wisk-edit" scale=".7"></icon>&nbsp;
      {{ translations.txtGenericEdit }}
    </b-button>

    <wiskModal v-model="modalVisible" size="md" @ok="ok" :title="translations.txtVenueOperationsInfo"
      :okDisabled="!isValid" :okText="translations.txtGenericSave">

      <wiskInputGroup style="padding: 0; min-height: 600px;" @errorCountChanged="isValid = !$event" @operationsChange="onChange" ref="localInputGroupInModal"
        v-if="modalVisible">

        <wiskInput infoTooltipKey="67098ebd-b8b4-489a-9cd5-fb7d0c7d5aaa" :autofocus="autofocus" :modelValue="selectedPlace ? selectedPlace.description || selectedPlace.name : searchQuery" requiredAutocompleteItem
          @autocompleteInput="onPlacesAutocompleteInput" @autocompleteSelected="onPlacesAutocompleteSelected" :disabled="disabled"
          autocompleteDisplayKey="description" :autocompleteItems="autocompletePlaces" @clear="onPlacesAutocompleteSelected(null)"
          :label="translations.txtGenericFindVenue" autocomplete :autocompleteMinChars="2" usedForGooglePlaces ref="placesAutocomplete"
          v-if="!showFields" autocompleteFirstItemSlotUsed>

          <template v-slot:autocompleteFirstItem>
            <b-button type="button" variant="primary" class="mt-2" size="sm" @mousedown="showFields = !showFields">
              <icon name="wisk-edit" scale=".7"></icon>&nbsp;
              {{ translations.txtGenericInputManually }}
            </b-button>
          </template>
        </wiskInput>

        <wiskInput infoTooltipKey="571cae41-1b02-4e67-9a7e-bf86965d1879" v-if="showFields" :label="translations.txtVenueOperationsName" inputType="text" operation="title" required :modelValue="title"
          :triggerInputOnLoad="!!title && !venue" :disabled="disabled" key="venueInput" inputClass="mb-3">
          <template v-slot:append>
            <b-button variant="link" class="m-0 border-0" @click="showFields = !showFields" style="position: absolute; right: 5px; top: 10px;"
              v-tooltip="translations.txtGenericFindVenue" :style="{ right: title ? '5px' : '25px' }">
              <icon name="wisk-address" />
            </b-button>
          </template>
        </wiskInput>

        <wiskSelect infoTooltipKey="472ffeb9-e9dc-4eef-bcb9-246d1000e459" v-if="showFields" :label="translations.txtGenericCountry" :modelValue="country" operation="country"
          :items="countries" :multiselectOptions="{}" trackBy="code" required />

        <wiskSelect infoTooltipKey="d6a6bad6-e7b0-4c2a-ab57-6200978164ab" :label="translations.txtGenericState" :modelValue="state" operation="state" v-if="showFields && states && states.length"
          :items="states" :multiselectOptions="{}" trackBy="code" required :key="country" />

        <wiskInput infoTooltipKey="0535be26-bcc9-4ed6-9f61-bf3c11161c19" :label="translations.txtGenericCity" :modelValue="city" operation="city" v-if="showFields" required :triggerInputOnLoad="!!city && !venue"
          key="cityInput" />

        <wiskSelect class="mt-3" infoTooltipKey="f4ab91d4-371b-4f11-9318-7e084c2abf68" :label="translations.txtGenericTimeZone" :modelValue="timezone" operation="timezone" v-if="showFields && timeZones && timeZones.length"
          :items="timeZones" titleKey="name" :multiselectOptions="{}" trackBy="name" required />

      </wiskInputGroup>
    </wiskModal>
  </wiskInput>

  <!-- TODO: remove duplication in the template html -->

  <wiskInputGroup style="padding: 0;" @errorCountChanged="isValid = !$event" @operationsChange="onChange" ref="localInputGroup"
    v-else>

    <wiskInput infoTooltipKey="7c0a9459-a322-4709-9caa-4c8b5fa87e93" :class="inputClass" :autofocus="autofocus" :modelValue="selectedPlace ? selectedPlace.description || selectedPlace.name : searchQuery" requiredAutocompleteItem
      @autocompleteInput="onPlacesAutocompleteInput" @autocompleteSelected="onPlacesAutocompleteSelected" :disabled="disabled"
      autocompleteDisplayKey="description" :autocompleteItems="autocompletePlaces" @clear="onPlacesAutocompleteSelected(null)"
      :label="translations.txtGenericFindVenue" autocomplete :autocompleteMinChars="2" usedForGooglePlaces ref="placesAutocomplete"
      v-if="!showFields" autocompleteFirstItemSlotUsed :key="searchInputKey">

      <template v-slot:autocompleteFirstItem>
        <b-button type="button" variant="primary" class="mt-2" size="sm" @mousedown="showFields = !showFields">
          <icon name="wisk-edit" scale=".7"></icon>&nbsp;
          {{ translations.txtGenericInputManually }}
        </b-button>
      </template>
    </wiskInput>

    <wiskInput infoTooltipKey="c8c2f9ec-0c98-4745-bd74-45fb578a03c3" :class="inputClass" v-if="showFields" :label="translations.txtVenueOperationsName" inputType="text" operation="title" required :modelValue="title"
      :triggerInputOnLoad="!!title" :disabled="disabled" key="venueInput">
      <template v-slot:append>
        <b-button variant="link" class="m-0 border-0" @click="showFields = !showFields" style="position: absolute; right: 5px; top: 10px;"
          v-tooltip="translations.txtGenericFindVenue" :style="{ right: title ? '5px' : '25px' }">
          <icon name="wisk-address" />
        </b-button>
      </template>
    </wiskInput>

    <wiskSelect infoTooltipKey="3bc7870f-df8d-40a8-ba8f-1df153318889" :class="inputClass" v-if="showFields" :label="translations.txtGenericCountry" :modelValue="country" operation="country"
      :items="countries" :multiselectOptions="{}" trackBy="code" required />

    <wiskSelect infoTooltipKey="30ba41ac-d855-439e-b877-8305e2076244" :class="inputClass" :label="translations.txtGenericState" :modelValue="state" operation="state" v-if="showFields && states && states.length"
      :items="states" :multiselectOptions="{}" trackBy="code" required :key="country" />

    <wiskInput infoTooltipKey="274ad077-0b7a-4ba5-9b64-d51c522baec8" :class="inputClass" :label="translations.txtGenericCity" :modelValue="city" operation="city" v-if="showFields" required :triggerInputOnLoad="!!city && !venue"
      key="cityInput" />

    <wiskSelect class="mt-2" :class="inputClass" infoTooltipKey="f4ab91d4-371b-4f11-9318-7e084c2abf68" :label="translations.txtGenericTimeZone" :modelValue="timezone" operation="timezone" v-if="showFields && timeZones && timeZones.length"
      :items="timeZones" titleKey="name" :multiselectOptions="{}" trackBy="name" required ref="timezoneSelector" />

  </wiskInputGroup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import api from '@/api'
import { guid } from '@/modules/utils'

const newOperations = [
  { type: 'title', value: null },
  { type: 'country', value: null },
  { type: 'state', value: null }
]

export default {
  name: 'VenueFinder',
  emits: ['change'],
  components: {},
  props: {
    autofocus: Boolean,
    venue: Object,
    preloadTitle: String,
    inputClass: String,
    disabled: Boolean
  },
  data() {
    return {
      modalVisible: false,
      ipInfo: null,
      autocompletePlaces: [],
      placesSearchSessionToken: guid(),
      selectedPlace: null,
      showFields: false,
      country: '',
      state: '',
      title: '',
      city: '',
      timezone: '',
      searchQuery: '',
      operations: [],
      isValid: true,
      inputGroupParent: null,
      searchInputKey: 0
    }
  },
  computed: {
    ...mapGetters(['countries', 'countriesByCode', 'timeZones']),
    ...mapState(['translations']),
    states() {
      let found = this.operations.find(o => o.type === 'country' && o.value)
      if (found) {
        return (this.countriesByCode[found.value] && this.countriesByCode[found.value].states) || []
      }
      if (this.venue && this.venue.country) {
        return (this.countriesByCode[this.venue.country] && this.countriesByCode[this.venue.country].states) || []
      }

      return (this.countriesByCode[this.country] && this.countriesByCode[this.country].states) || []
    },
    venueInfoSummary() {
      if (this.venue && this.venue.id) {
        let country = this.countriesByCode[this.venue.country] || { title: '' }

        return `${this.venue.title}, ${this.venue.city}, ${this.venue.state ? this.venue.state + ', ' + country.title : country.title}`
      }

      return ''
    }
  },
  methods: {
    ...mapActions([]),
    setValidState() {
      if (this.inputGroupParent && this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: 'VenueFinder', hasError: !this.isValid })
      }
    },
    onPlacesAutocompleteInput(query) {
      this.searchQuery = query

      api.searchGooglePlaces(query, this.placesSearchSessionToken).then(result => {
        this.autocompletePlaces = result
      })
    },
    onPlacesAutocompleteSelected(place) {
      if (place) {
        this.setOperation({ operation: { value: place.place_id, type: 'google_places_id' }, operationTypes: { set: 'google_places_id' } })

        this.placesSearchSessionToken = guid()

        if (Array.isArray(place.terms)) {
          this.title = (place.terms[0] || { value: place.description }).value
          this.selectedPlace = { name: place.description }
          this.setOperation({ operation: { value: this.title, type: 'title' }, operationTypes: { set: 'title' } })
        }
      } else {
        this.country = ''
        this.city = ''
        this.state = ''
        this.operations = newOperations.map(o => ({ ...o }))
      }
    },
    setOperation(payload) {
      if (this.$refs.localInputGroup && this.$refs.localInputGroup.setOperation) {
        this.$refs.localInputGroup.setOperation(payload)
      } else if (this.$refs.localInputGroupInModal && this.$refs.localInputGroupInModal.setOperation) {
        this.$refs.localInputGroupInModal.setOperation(payload)
      }
    },
    onChange(operations) {
      this.operations = [...operations]

      if (this.venue && !this.venue.id) {
        this.$emit('change', this.operations)
      }
    },
    ok() {
      if (this.venue && this.venue.id) {
        this.$emit('change', this.operations)
      }
    }
  },
  mounted() {
    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }

    this.setValidState()
  },
  beforeUnmount() {
    this.isValid = true
    this.setValidState()
  },
  watch: {
    preloadTitle: {
      handler(preloadTitle) {
        if (!this.venue?.id && preloadTitle) {
          this.searchQuery = preloadTitle

          setTimeout(() => {
            this.searchInputKey++
            this.onPlacesAutocompleteInput(preloadTitle)
          }, 500)
        }
      },
      immediate: true
    },
    showFields() {
      if (this.$refs.localInputGroup && this.$refs.localInputGroup.reset) {
        this.$refs.localInputGroup.reset()
      }
      if (this.$refs.placesAutocomplete && this.$refs.placesAutocomplete.clearAutocomplete) {
        this.$refs.placesAutocomplete.clearAutocomplete()
      }

      setTimeout(() => {
        if (this.$refs.timezoneSelector && this.$refs.timezoneSelector.triggerOperation && this.venue && !this.venue.id && !this.timezone) {
          api.getIpInfo().then(result => {
            if (result && result.data) {
              this.ipInfo = result.data

              if (this.ipInfo.timezone && this.$refs?.timezoneSelector?.triggerOperation) {
                this.timezone = this.ipInfo.timezone

                setTimeout(() => {
                  this.$refs.timezoneSelector.triggerOperation()
                }, 100)
              }
            }
          })
        }
      }, 0)

      this.isValid = false
      this.country = (this.venue && this.venue.country) || ''
      this.state = (this.venue && this.venue.state) || ''
      this.city = (this.venue && this.venue.city) || ''
    },
    isValid: { handler: 'setValidState' },
    venue: {
      handler(venue) {
        if (venue && venue.id) {
          this.country = venue.country
          this.state = venue.state
          this.title = venue.title
          this.timezone = venue.timezone
          this.city = venue.city
          this.showFields = true
        }
      },
      deep: true,
      immediate: true
    },
    title(title) {
      this.searchQuery = title
    },
    searchQuery(searchQuery) {
      if (searchQuery) {
        this.title = searchQuery
      }
    }
  }
}
</script>

<style lang="scss"></style>
