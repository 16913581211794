<template>
  <div class="w-100 h-100 wisk-grid-selected-rows-actions-panel">
    <b-row style="height: 40px;">
      <b-col cols="10" class="mt-2 px-">
        <h4 class="mb-0 mt-1 ms-2 text-center">{{ countDescription }} </h4>
      </b-col>
      <b-col cols="1" class="px-0">
        <b-button variant="link" @click="deselectAll" class="float-end pt-2 pe-1">
          <icon name="wisk-exit" class="text-danger" :scale="1.2" />
        </b-button>
      </b-col>
    </b-row>

    <b-container fluid class="p-1" style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 40px);" :key="key" v-if="rows && rows.length">
      <b-row v-for="actionConfig in actions" :key="actionConfig.key" :class="{ 'd-none': actionConfig.hide }">
        <b-col class="text-center mt-3 px-4">

          <b-button class="w-100" v-if="actionConfig.type === 'button'" :disabled="actionConfig.disabled" :variant="actionConfig.variant"
            @click="actionConfig.action(rows)" v-tooltip="actionConfig.tooltip">
            {{ actionConfig.title }}
          </b-button>

          <genericInput v-if="actionConfig.type === 'genericInput'" v-bind="actionConfig.inputTypeAttrs" @operation="actionConfig.operation($event, rows)" />

          <component :is="actionConfig.is" v-if="actionConfig.is && actionConfig.type === 'dynamicInput'" v-bind="actionConfig.inputTypeAttrs" @clearSelection="deselectAll"
            @operation="actionConfig.operation($event, rows)" @action="actionConfig.action($event, rows)" @submit="actionConfig.submit($event, rows)" :rows="rows" />

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import inviteToVenuesSelectedRowsAction from '@/components/users/InviteToVenuesSelectedRowsAction'
import inviteUsersSelectedRowsAction from '@/components/users/InviteUsersSelectedRowsAction'
import subrecipesCopyToVenues from '@/components/subrecipes/SubrecipesCopyToVenues'
import POSItemsCopyToVenueRowAction from '@/components/posItems/POSItemsCopyToVenueRowAction'

export default {
  name: 'WiskGridSelectedRowsActionsPanel',
  components: { inviteToVenuesSelectedRowsAction, inviteUsersSelectedRowsAction, subrecipesCopyToVenues, POSItemsCopyToVenueRowAction },
  props: {},
  data() {
    return {
      rows: [],
      actions: [],
      doesExternalFilterPass: () => true,
      unMounted: false,
      countDescription: '',
      key: 1
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    rowsSelected(event) {
      let nodes = (event.api.getSelectedNodes() || []).filter(this.doesExternalFilterPass),
        rows = nodes.map(n => n.data).filter(r => r && !r.wiskRowHidden)

      this.rows = rows

      this.countDescription = this.translations.translate(rows.length > 1 ? 'tplWiskGridRowsSelected' : 'tplWiskGridRowSelected', { '{a}': rows.length })

      if (this.params && this.params.getWiskSelectedRowsActions) {
        setTimeout(() => {
          let actions = this.params.getWiskSelectedRowsActions()
          if (actions?.length) {
            this.actions = actions.map(a => {
              if (a?.getHidden) {
                a.hide = a.getHidden(this.rows)
              }
              return a
            })
          } else {
            this.actions = []
          }
        }, 0)
      }
    },
    deselectAll() {
      this.params.api && this.params.api.deselectAll()
    }
  },
  mounted() {
    if (this.params && this.params.doesExternalFilterPass) {
      this.doesExternalFilterPass = this.params.doesExternalFilterPass
    }

    setTimeout(() => {
      if (!this.unMounted) {
        this.params.api && this.params.api.addEventListener('selectionChanged', this.rowsSelected)
      }
    }, 500)
  },
  beforeUnmount() {
    this.unMounted = true
    this.params.api && this.params.api.removeEventListener('selectionChanged', this.rowsSelected)
  },
  watch: {}
}
</script>

<style lang="scss">
.wisk-grid-selected-rows-actions-panel {
  padding-bottom: 10px;

  .form-group.wisk-input:not(.modal-body .form-group.wisk-input),
  .form-group.wisk-select:not(.modal-body .form-group.wisk-select) {
    margin-bottom: 0;
  }
}
</style>
