<template>
  <div class="app" :class="[browserName, deviceType]" v-if="!forceLoadingScreen">
    <portal v-if="mobileAppStoreDialogVisible" to="pre-content">
      <b-alert variant="warning" show class="mb-1">
        {{ translations.txtWebAppNotOptimizedMobile }}
      </b-alert>
    </portal>

    <router-view v-if="routerViewVisible" />

    <div v-for="(actions, key) in localGlobalActions" :key="key">
      <div v-for="action in actions || []" :key="action.key">
        <component :editAction="action" :is="key" />
      </div>
    </div>

    <userOnboarding v-if="!signupFlow && !waitingForVenue && !verifyEmail && user && venue && !venue.id" />

    <div v-if="mobileAppStoreDialogVisible">
      <wiskModal :title="translations.txtOpenInAppstoreTitle" visible hideHeaderExtra>
        <h5 class="p-2">
          {{ translations.txtOpenInAppstore }}
        </h5>

        <template v-slot:modal-footer>
          <b-button variant="link" href="https://apps.apple.com/ca/app/wisk-bar-inventory-software/id1077887014">
            <b-img class="" src="/img/app_store.svg" />
          </b-button>
        </template>
      </wiskModal>
    </div>

    <div v-if="(venue.subscription.coupon === 'covid19' || venue.subscription.coupon === 'pause_coupon') && !covidOverride">
      <wiskModal visible hideHeaderInner hideFooter centered preventClose>
        <h4 class="p-2 text-center">
          {{ user.venue.title }}
        </h4>
        <h4 class="p-2 text-center">
          {{ translations.txtUserOnboardingReopeningTitle }}
        </h4>
        <p class="text-center">
          {{ translations.txtUserOnboardingReopeningMessage }}
        </p>
        <div class="d-flex justify-content-center">
          <b-button @click="removeCovidCoupon" variant="primary" :disabled="user?.god_mode" class="mt-4"> {{ translations.txtGenericResumeSubscription }} </b-button>

          <b-button @click="covidOverride = true" variant="success" class="mt-4 ms-3" v-tooltip="'Button visible only for Wisk Admin'" v-if="user && user.god_mode">
            {{ translations.txtGenericViewAccount }}
          </b-button>
        </div>

        <small class="text-primary text-small d-flex justify-content-center mt-3">
          {{ translations.txtHelpForBackup }}
          <b-button @click="$intercom && $intercom.show()" variant="link" style="font-size: inherit; font-weight: inherit; padding: 0; margin-top: -1px; margin-left: 3px">
            {{ translations.txtGenericSendUsMessage }}
          </b-button>
        </small>
      </wiskModal>
    </div>

    <loadingScreen />

    <div v-if="!routerViewVisible">
      <h2 class="text-center mt-5 pt-5 text-dark fw-light font-monospace">Preparing to load data...</h2>
      <div style="position: absolute; bottom: 100px; left: 100px;">
        <img src="/img/wisk-logo.svg" style="width: 100px;" />
      </div>
      <div class="font-monospace" style="position: absolute; bottom: 100px; right: 100px;">
        {{ wiskWebAppVersion }} &nbsp; | &nbsp; {{ wiskWebAppCOMMITHASH }}
      </div>
    </div>

    <b-button v-if="infoTooltipsDirty && user?.god_mode" variant="primary" size="lg" style="position: absolute; bottom: 50px; right: 50px; z-index: 99999;" @click="downloadTooltipsFile">
      Download file with updated tooltips
    </b-button>
  </div>
  <loadingScreen v-if="forceLoadingScreen" forceVisible />
  <userActivityTracker v-if="user" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import uaParser from 'ua-parser-js'
import api from '@/api'
import { getURLParam } from '@/modules/utils'

import userOnboarding from '@/views/Auth/UserOnboarding'
import addCard from '@/components/subscriptions/AddCard'
import adPopup from '@/components/marketing/AdPopup'
import allergens from '@/components/bottles/AllergensEdit'
import barcodePrinterSetup from '@/components/venues/BarcodePrinterSetup'
import categoryEdit from '@/components/families/CategoryEdit'
import confirmDialog from '@/components/common/WiskConfirmDialogAction'
import consumption from '@/components/consumption/ConsumptionGridGlobal'
import customFieldEdit from '@/components/customFields/CustomFieldEdit'
import customFields from '@/components/customFields/CustomFields'
import distributorEdit from '@/components/distributors/DistributorEdit'
import distributors from '@/components/distributors/DistributorsEdit'
import draftInvoiceEdit from '@/components/draftInvoices/DraftInvoiceEdit'
import draftInvoiceItemSearch from '@/components/draftInvoices/DraftInvoiceItemSearch'
import environmentSwitch from '@/components/venues/EnvironmentSwitch'
import families from '@/components/families/FamiliesEdit'
import familyEdit from '@/components/families/FamilyEdit'
import fileUpload from '@/components/common/FileUploader'
import glAccountEdit from '@/components/accounting/GLAccountEdit'
import glAccounts from '@/components/accounting/GLAccounts'
import gridViewEdit from '@/components/grids/WiskGridViewEdit'
import helpViewer from '@/components/users/HelpViewer'
import independentInventoryEdit from '@/components/inventories/IndependentInventoryEdit'
import ingredientsBatchAdd from '@/components/common/IngredientsBatchAdd'
import inventoryDetails from '@/components/inventories/InventoryDetails'
import inventoryEdit from '@/components/inventories/InventoryEdit'
import inviteUsers from '@/components/users/InviteUsers'
import itemDetails from '@/components/bottles/ItemDetails'
import itemEdit from '@/components/bottles/ItemEdit'
import itemMergeGroupPrepare from '@/components/bottles/ItemMergeGroup'
import itemOperationErrorHandler from '@/components/bottles/ItemOperationErrorHandler'
import itemSearchDialog from '@/components/bottles/ItemSearchDialog'
import itemsForDistributor from '@/components/bottles/DistributorItems'
import itemsMerge from '@/components/bottles/ItemsMerge'
import itemsUpload from '@/components/bottles/ItemsUploader'
import itemSwap from '@/components/bottles/ItemSwap'
import itemVariantEdit from '@/components/bottles/ItemVariantEdit'
import loadingScreen from '@/components/common/LoadingScreen'
import locationEdit from '@/components/locations/LocationEdit'
import mediaViewer from '@/components/viewers/MediaViewer'
import mobileNavigation from '@/components/sidebar/MobileNavigation'
import movementAdjustmentReasonEdit from '@/components/movements/MovementAdjustmentReasonEdit'
import movementAdjustmentReasons from '@/components/movements/MovementAdjustmentReasons'
import movementEdit from '@/components/movements/MovementEdit'
import movementExtraLineReasonEdit from '@/components/movements/MovementExtraLineReasonEdit'
import movementExtraLineReasons from '@/components/movements/MovementExtraLineReasons'
import movementsBatchErrorHandler from '@/components/movements/MovementsBatchErrorHandler'
import organizationEdit from '@/components/organizations/OrganizationEdit'
import parentVenueItems from '@/components/bottles/ParentVenueItems'
import pdfSplitEdit from '@/components/draftInvoices/DraftInvoicePageManager'
import planFeatureAd from '@/components/gateKeeper/PlanFeatureAd'
import planSelectorEdit from '@/components/subscriptions/PlanSelectorEdit'
import POSEdit from '@/components/venues/POSEdit'
import posItemEdit from '@/components/posItems/POSItemEdit'
import posItemSearchDialog from '@/components/posItems/POSItemSearchDialog'
import posModifierEdit from '@/components/posModifiers/POSModifierEdit'
import purchaseOrderEdit from '@/components/orders/PurchaseOrderEdit'
import referUsers from '@/components/marketing/ReferUsers'
import salesUpload from '@/components/sales/SalesUploader'
import salesReimport from '@/components/sales/SalesReimport'
import screenshotTool from '@/components/common/ScreenshotTool'
import servingSizeEdit from '@/components/servingSizes/ServingSizeEdit'
import servingSizes from '@/components/servingSizes/ServingSizes'
import subrecipeEdit from '@/components/subrecipes/SubrecipeEdit'
import supportEmailForm from '@/components/common/SupportEmailForm'
import taxEdit from '@/components/accounting/TaxEdit'
import taxes from '@/components/accounting/Taxes'
import userEdit from '@/components/users/UserEdit'
import venueEdit from '@/components/venues/VenueEdit'
import venueTransferEdit from '@/components/venueTransfers/VenueTransferEdit'
import venueTransfersReport from '@/components/venueTransfers/VenueTransfersReport'
import wiskDistributorEdit from '@/components/distributors/WiskDistributorEdit'
import wiskModal from '@/components/common/WiskModal'
import UserActivityTracker from '@/components/users/UserActivityTracker'
import MultiVenueAnalytics from '@/components/dashboard/MultiVenueAnalytics'

export default {
  name: 'Wisk',
  components: {
    addCard,
    adPopup,
    allergens,
    barcodePrinterSetup,
    categoryEdit,
    confirmDialog,
    consumption,
    customFieldEdit,
    customFields,
    distributorEdit,
    distributors,
    draftInvoiceEdit,
    draftInvoiceItemSearch,
    environmentSwitch,
    families,
    familyEdit,
    fileUpload,
    glAccountEdit,
    glAccounts,
    gridViewEdit,
    helpViewer,
    independentInventoryEdit,
    ingredientsBatchAdd,
    inventoryDetails,
    inventoryEdit,
    inviteUsers,
    itemDetails,
    itemEdit,
    itemMergeGroupPrepare,
    itemOperationErrorHandler,
    itemSearchDialog,
    itemsForDistributor,
    itemsMerge,
    itemsUpload,
    itemSwap,
    itemVariantEdit,
    loadingScreen,
    locationEdit,
    mediaViewer,
    mobileNavigation,
    movementAdjustmentReasonEdit,
    movementAdjustmentReasons,
    movementEdit,
    movementExtraLineReasonEdit,
    movementExtraLineReasons,
    movementsBatchErrorHandler,
    organizationEdit,
    parentVenueItems,
    pdfSplitEdit,
    planFeatureAd,
    planSelectorEdit,
    POSEdit,
    posItemEdit,
    posItemSearchDialog,
    posModifierEdit,
    purchaseOrderEdit,
    referUsers,
    salesUpload,
    salesReimport,
    screenshotTool,
    servingSizeEdit,
    servingSizes,
    subrecipeEdit,
    supportEmailForm,
    taxEdit,
    taxes,
    userEdit,
    userOnboarding,
    venueEdit,
    venueTransferEdit,
    venueTransfersReport,
    wiskDistributorEdit,
    wiskModal,
    UserActivityTracker,
    MultiVenueAnalytics
  },
  data() {
    return {
      wiskWebAppVersion: window.wiskWebAppVersion,
      wiskWebAppCOMMITHASH: window.wiskWebAppCOMMITHASH,
      actionsMapper: {
        venueEdit: 'venue',
        venue: 'venueEdit',
        distributorEdit: 'distributor',
        distributor: 'distributorEdit',
        referUsers: 'refer',
        refer: 'referUsers',
        transfer_request: 'venueTransferEdit',
        venueTransferEdit: 'transfer_request',
        POSEdit: 'pos_settings',
        pos_settings: 'POSEdit',
        draftInvoiceEdit: 'draft_invoice',
        draft_invoice: 'draftInvoiceEdit',
        movementEdit: 'movement',
        movement: 'movementEdit',
        helpViewer: 'help',
        help: 'helpViewer',
        inventoryDetails: 'inventory',
        inventory: 'inventoryDetails',
        inventoryEdit: 'inventory_edit',
        inventory_edit: 'inventoryEdit',
        independentInventoryEdit: 'independent_inventory',
        independent_inventory: 'independentInventoryEdit',
        itemEdit: 'item',
        item: 'itemEdit',
        itemDetails: 'item_details',
        item_details: 'itemDetails',
        posItemEdit: 'pos_item',
        pos_item: 'posItemEdit',
        pos_modifier: 'posModifierEdit',
        posModifierEdit: 'pos_modifier',
        subrecipeEdit: 'subrecipe',
        subrecipe: 'subrecipeEdit',
        purchaseOrderEdit: 'order',
        order: 'purchaseOrderEdit',
        wiskDistributorEdit: 'wisk_distributor',
        wisk_distributor: 'wiskDistributorEdit',
        multiVenueAnalytics: 'multivenue_analytics',
        multivenue_analytics: 'multiVenueAnalytics'
      },
      browserName: '',
      deviceType: '',
      verifyEmail: false,
      covidOverride: false,
      lastRouteWroteTime: 0,
      mobileAppStoreDialogVisible: false,
      minBrowserVersions: {
        Chrome: 60,
        Edge: 14,
        Firefox: 57,
        IE: 12,
        Safari: 10,
        Opera: 50
      },
      localGlobalActions: {}
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'globalActions', 'waitingForVenue', 'venuesHighLevel', 'signupFlow', 'currentPermissionsByType', 'initialDBLoadingProgress', 'infoTooltipsDirty']),
    ...mapGetters(['venue']),
    forceLoadingScreen() {
      return !!this.initialDBLoadingProgress
    },
    routerViewVisible() {
      let coupons = ['pause_coupon', 'covid19'],
        routes = ['signin',
          'logout',
          'signup',
          'auth',
          'deep_link',
          'public_order',
          'public_no_credit_card_email',
          'public_link_to_email',
          'public_lightspeed_signup',
          'public_signup_lightspeed',
          'public_redirect',
          'dashboard_printable'
        ]

      return (
        (!!this.user && (!coupons.includes(this.venue?.subscription?.coupon) || this.covidOverride)) || routes.includes(this.$route.name)
      )
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'changeActiveVenue', 'setWindowResizeKey', 'setDeviceInfo', 'downloadTooltipsFile']),
    removeCovidCoupon() {
      if (getURLParam('coupon') === '50off') {
        api.postCovidCoupon()
      } else {
        api.removeCovidCoupon()
      }
    },
    onWindowResize() {
      clearTimeout(this.onResizeTimeoutId)
      this.onResizeTimeoutId = setTimeout(() => {
        this.setWindowResizeKey(new Date().getTime())
      }, 1000)
    }
  },
  mounted() {
    let parser = uaParser()
    this.browserName = parser.browser.name
    this.deviceType = parser.device.type
    this.setDeviceInfo(parser)

    window.addEventListener('resize', this.onWindowResize)
  },
  beforeUnmount() {
    clearTimeout(this.onResizeTimeoutId)
    window.removeEventListener('resize', this.onWindowResize)
  },
  watch: {
    routerViewVisible: {
      immediate: true,
      handler(routerViewVisible) {
        console.log('routerViewVisible', routerViewVisible)
      }
    },
    globalActions: {
      deep: true,
      handler(globalActions) {
        this.localGlobalActions = { ...globalActions }

        setTimeout(() => {
          let query = { ...this.$route.query },
            actionName = null,
            actionId = 0,
            itemId = 0,
            count = 0,
            forceHistory = false
          console.log('globalActions', JSON.parse(JSON.stringify(globalActions)))
          Object.keys(globalActions).forEach(key => {
            let actions = globalActions[key]
            actions.forEach(action => {
              if (action && (action.id || action.item_id || action.forceHistory)) {
                if (!count) {
                  forceHistory = action.forceHistory
                  actionName = this.actionsMapper[key]
                  actionId = action.id
                  itemId = action.item_id
                }

                count++
              }
            })
          })
          if (actionName && count === 1 && (actionId || itemId || forceHistory)) {
            query = { ...query, action: actionName }

            if (actionId) {
              query.action_id = actionId
            }
            if (itemId) {
              query.item_id = itemId
            }

            this.$router.push({ name: this.$route.name, query }).catch(() => { })
            this.lastRouteWroteTime = new Date().getTime()
          } else if (!count) {
            //only allow one single action at a time, deep nested modal on modal are confusing to map to URL
            delete query.action
            delete query.tab
            delete query.action_id
            delete query.item_id

            this.$router.replace({ name: this.$route.name, query }).catch(() => { })
          }
        }, 0)
      }
    },
    $route: {
      handler(to, from) {
        if (to.query && to.query.mode === 'verifyEmail') {
          this.verifyEmail = true
        }

        // setTimeout(() => {
        if (this.lastRouteWroteTime + 2000 < new Date().getTime()) {
          if (from && from.query && from.query.action && this.actionsMapper[from.query.action]) {
            let count = 0
            Object.keys(this.globalActions).forEach(key => {
              let actions = this.globalActions[key]
              actions.forEach(current => {
                if (current) {
                  count++
                }
              })
            })
            if (count === 1) {
              this.setGlobalAction({ type: this.actionsMapper[from.query.action], action: null })
            }
          }

          if (to.query && to.query.action && this.actionsMapper[to.query.action]) {
            let actions = this.globalActions[this.actionsMapper[to.query.action]],
              id = parseInt(to.query.action_id, 10) || null,
              itemId = parseInt(to.query.item_id, 10) || null,
              tab = parseInt(to.query.tab, 10) || null,
              action = {}

            if (id) {
              action.id = id
            }
            if (itemId) {
              action.item_id = itemId
            }
            if (tab) {
              action.tab = tab
            }
            if (!action.id && !action.item_id) {
              action.forceHistory = true
            }

            if (!actions || !actions.length || (actions && actions.length === 1 && ((itemId && actions[0].item_id !== itemId) || (id && actions[0].id !== id)))) {
              this.setGlobalAction({ type: this.actionsMapper[to.query.action], action })
            }
          }
        }
        // }, 0)
      },
      deep: false,
      immediate: true
    },
    venuesHighLevel() {
      if (this.user && this.venue && this.$route.query && this.$route.query.venue_id && this.venuesHighLevel) {
        let venueId = parseInt(this.$route.query.venue_id, 10)

        if (venueId && this.venuesHighLevel && this.venuesHighLevel.length && this.venue.id !== venueId && this.venuesHighLevel.find(v => v.id === venueId)) {
          this.changeActiveVenue({ venueId, userId: this.user.id })
        }

        if (venueId && this.venue.id === venueId) {
          let query = { ...this.$route.query }
          delete query.venue_id

          this.$router.replace({ name: this.$route.name, query }).catch(() => { })
        }
      }
    },
    user: {
      handler() {
        if (this.user && !this.signupFlow && this.deviceType === 'mobile' && !['signin', 'logout', 'signup', 'auth', 'deep_link'].includes(this.$route.name)) {
          this.mobileAppStoreDialogVisible = true
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#covid19 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
