<template>
  <div>
    <template v-if="data">
      <h4 class="mb-3 ms-2">{{ translations.txtPOSItemHistoryDescription }}</h4>
      <highcharts :options="historyGraphOptions"/>
      <div v-if="item">
        <h3>{{ formatDate(item.business_day, { format: 'DDDD' }) }}</h3>
        <b-row class="mb-3 fs-5 " style="flex: 1">
          <b-col md="2" cols="12" class="mb-2-m text-md-start text-start">
            <span> {{ translations.txtGenericPrice }}: </span>
            <span class="text-bold1"> {{ currencyFormat(item.data.cost_alert.price) }}</span>
          </b-col>
          <b-col md="2" cols="12" class="mb-2-m  text-md-center text-start">
            <span> {{ translations.txtGenericCost }}: </span>
            <span class="text-bold1"> {{ costFormatted }}</span>
          </b-col>
          <b-col md="2" cols="12" class="mb-2-m text-md-center text-start">
            <span> {{ translations.txtGenericProfit }}: </span>
            <span class="text-bold1" :class="profitDisplayColor"> {{ profitFormatted }}</span>
          </b-col>
          <b-col md="3" cols="12" class="mb-2-m text-md-end text-start">
            <span> {{ translations.txtGenericCostPercentage }}: </span>
            <span class="text-bold1"> {{ costPercentageFormatted }}</span>
          </b-col>
          <b-col v-if="item && item.stats" md="3" cols="12" class="mb-2-m  text-md-center text-start">
            <span> {{ translations.txtPOSItemProfitPercentage }}: </span>
            <span class="text-bold1" :class="profitDisplayColor"> {{ profitPercentageFormatted }}</span>
          </b-col>
        </b-row>
        <b-table striped hover :items="ingredients" :fields="ingredientsColumns" class="usedcost-negative-table" />
      </div>
    </template>
    <wiskLoading loading v-else/>
  </div>
</template>

<script>
import get from 'lodash.get'
import { mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import { currencyFormat, formatDate, percentageFormat } from '@/modules/utils.js'
import api from '@/api/index'

export default {
  name: 'POSItemHistory',
  components: { },
  props: {
    posItem: Object,
  },
  data() {
    return {
      data: null,
      item: null
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['translations', 'allergens', 'subrecipesById', 'currentPermissionsByType']),
    profitFormatted() {
      return currencyFormat(get(this.item, 'data.recipe_cost.stats.cost.profit', 0))
    },
    profitDisplayColor() {
      return get(this.item, 'data.recipe_cost.stats.cost.profit', 0) > 0 ? 'text-success' : 'text-danger'
    },
    costFormatted() {
      return currencyFormat(get(this.item, 'data.cost_alert.cost', 0))
    },
    costPercentageFormatted() {
      return percentageFormat(get(this.item, 'data.recipe_cost.stats.cost.percentage', 0))
    },
    profitPercentageFormatted() {
      return percentageFormat(1 - get(this.item, 'data.recipe_cost.stats.cost.percentage', 0))
    },
    historyGraphOptions() {
      const chartData = this.data?.history || [],
        dates = [],
        costPoints = [],
        pricePoints = [],
        profitPoints = []

      chartData.forEach((i, index) => {
        const ms = DateTime.fromJSDate(new Date(i.business_day)).toMillis(),
          pointData = { x: ms, index }
        dates.push(ms)
        costPoints.push({ ...pointData, y: i.data.cost_alert.cost })
        pricePoints.push({ ...pointData, y: i.data.cost_alert.price })
        profitPoints.push({ ...pointData, y: i.data.recipe_cost.stats.cost.profit })
      })
      const instance = this

      return {
        title: {
          text: undefined
        },
        tooltip: {
          pointFormatter() {
            return `<b>${this.series.name}</b>: ${currencyFormat(this.y)}<br/>`
          },
          shared: true
        },
        'xAxis': {
          'type': 'datetime',
          'labels': {
            'format': '{value:%b %e}',
            'rotation': -45,
            'step': 2
          },
          'tickPositions': dates
        },
        'yAxis': {
          labels: {
            format: '${text}'
          },
          'title': {
            'text': ''
          },
          'tickmarkPlacement': 'on',
          'allowDecimals': true
        },
        legend: {
          enabled: true,
          verticalAlign: 'top',
          align: 'right',
          floating: true,
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          line: {
            cursor: 'pointer',
            point: {
              events: {
                click() {
                  instance.item = chartData[this.options.index]
                }
              }
            },
            allowPointSelect: true,
            marker: {
              states: {
                select: {
                  fillColor: 'red',
                  lineWidth: 4,
                  lineColor: 'red'
                }
              },
              enabled: true
            },
          }
        },
        'series': [
          {
            name: this.translations.txtGenericCost,
            'data': costPoints,
          },
          {
            name: this.translations.txtGenericPrice,
            'data': pricePoints,
          },
          {
            name: this.translations.txtGenericProfit,
            'data': profitPoints,
          }
        ]
      }
    },
    ingredientsColumns() {
      return [
        { key: 'title', label: this.translations.txtGenericTitle },
        { key: 'servingSize', label: this.translations.txtPOSItemsServingSize },
        { key: 'cost', label: this.translations.txtGenericCost },
        { key: 'costPercentage', label: this.translations.txtGenericCostPercentage }
      ]
    },
    ingredients() {
      const data = this.item?.data.recipe_cost.ingredients || []
      return data.map(i => ({
        title: i.title,
        servingSize: `${i.measurement.quantity} ${i.measurement.unit_of_measurement}`,
        cost: currencyFormat(i.stats.cost.dollars),
        costPercentage: percentageFormat(i.stats.cost.percentage),
      }))
    }
  },
  methods: {
    currencyFormat,
    percentageFormat,
    formatDate
  },
  mounted() {
    api.getPOSItemHistory(this.posItem.id).then(response => {
      this.data = response
    })
  }
}
</script>

<style scoped lang="scss">

</style>
