<template>
  <wiskSelect :label="translations.txtMenuSwitchVenue" :items="venuesHighLevel" :modelValue="selected" class="wisk-venue-switch mb-0" :multiselectOptions="{ allowEmpty: false }"
    @change="switchToVenue" v-if="venuesHighLevel && venuesHighLevel.length" :addNewItem="addNewVenueMultiselectAction" :deselectLabel="' '" :selectLabel="' '" :size="size">

    <template v-slot:option="{ option }">
      <a :href="getSwitchVenueURL(option)" @click.exact.prevent @click.ctrl.exact.stop @click.meta.exact.stop style="color: unset">
        <div class="customElementPadding">
          {{ option?.title }}
        </div>
      </a>
    </template>

    <template v-slot:singleLabel="{ option }">
      {{ option?.title }}
      <span v-if="user.god_mode && venue.id === option.id && venue.subscription.status !== 'active'" class="text-danger">({{ venue.subscription.status }})</span>
    </template>
  </wiskSelect>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getPreferences, setPreferences } from '@/modules/storage'
import { isLocalhost } from '@/modules/utils'

export default {
  name: 'VenueSwitch',
  components: {},
  props: { size: { type: String, default: 'md' } },
  data() {
    return {
      venueSwitchHistory: []
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['user', 'translations', 'venuesHighLevel', 'currentPermissionsByType']),
    selected() {
      return this.venuesHighLevel && this.venuesHighLevel.find(v => v.id === this.venue.id)
    },
    addNewVenueMultiselectAction() {
      if (this.currentPermissionsByType.venue_add) {
        return {
          action: (caller, query) => {
            if (this.user?.god_mode) this.setGlobalAction({ type: 'venueEdit', action: { id: 0, preloadTitle: query }, resetBeforeOpen: true })
            else window.open(this.$upgradeMeetingLink, '_blank')
          },
          label: this.translations.txtVenuesNew
        }
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'changeActiveVenue']),
    getSwitchVenueURL(venue) {
      return window.location.pathname.replace(this.selected?.id, venue?.id)
    },
    switchToVenue(venue) {
      if (venue.id !== 949987 && isLocalhost) {
        this.venueSwitchHistory = this.venueSwitchHistory.filter(v => v.id !== venue.id && v.id !== 949987)
        this.venueSwitchHistory.unshift(venue)
        this.venueSwitchHistory = this.venueSwitchHistory.slice(0, 11)
        setPreferences('venueSwitchHistory', JSON.stringify(this.venueSwitchHistory))
      }

      this.changeActiveVenue({ venueId: venue.id, userId: this.user.id })
    }
  },
  mounted() {
    if (isLocalhost) {
      this.venueSwitchHistory = JSON.parse(getPreferences('venueSwitchHistory') || '[]')
    }
  }
}
</script>

<style lang="scss">
.wisk-venue-switch {
  z-index: 25;
  position: relative;

  .multiselect__content-wrapper {
    width: auto;
  }
}
</style>
