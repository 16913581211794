<template>
  <span class="wisk-price-input-container">
    <span v-if="showPlainText"> {{ formattedValue }} </span>

    <div v-if="!inline && !showPlainText" class="wisk-item-price-container" :id="domId">
      <wiskInput infoTooltipKey="ede4ce06-6c39-4929-8175-2fd0181eee7e" fitChildren :fitChildrenCols="2" :label="label || translations.txtGenericCost" :modelValue="formattedValue" showPlainText disabled :key="key"
        class="px-0 light-control combined-control-input wisk-price-input" :class="inputClass" :helperText="discountFormattedValue">
        <b-button type="button" variant="link" class="float-end h-100 middle-center" size="sm" @click="modalVisible = true" v-if="!disabledLocal">
          <icon name="wisk-edit" scale=".7"></icon>&nbsp;
        </b-button>
        <slot></slot>
      </wiskInput>

      <wiskModal v-model="modalVisible" size="md" @ok="setOperation()" @hide="reset" :title="title || translations.txtGenericCost"
        :okDisabled="!valid" :okText="translations.txtGenericSave" hideFooterExtra>
        <wiskInputGroup @errorCountChanged="setValidState" class="px-5 pt-2" :disabled="disabledLocal" style="min-height: 500px;">
          <b-row>
            <b-col cols="12" class="">
              <wiskInput infoTooltipKey="5f97d3d6-8708-4457-ab65-986fa0e58877" required :label="label || translations.txtGenericCost" v-model="priceValue" inputType="number" :decimals="4"
                :prefix="currency" :triggerInputOnLoad="triggerInputOnLoad" :disabled="disabledLocal" decimalsAsNeeded :minDecimals="2" />
            </b-col>
            <b-col cols="12" class="">
              <wiskSelect infoTooltipKey="a8053674-2889-49d0-8715-aea5c2eeec6c" :items="priceTypes" :disabled="!itemComputed || disabledLocal" required v-model="selectedPriceType"
                :label="translations.txtMovementEditPricePerUnitOrCase" :triggerInputOnLoad="triggerInputOnLoad" />
            </b-col>
            <b-col cols="12" v-if="discountAvailable">
              <wiskInput infoTooltipKey="7d7572f9-72fe-41ae-b7ed-f587e5f76cc9" :label="translations.translate('tplMovementEditDiscountPerUnitOrCase', { '{a}': selectedPriceType || '' })"
                inputType="number" fitChildren fitChildrenTight :fitChildrenCols="3" :key="discountType" :customFormatter="discountFormatter" v-model="discountValuesMap[discountType]"
                :transformerOut="discountType === 'percent' ? z => z / 100 : undefined" :transformerIn="discountType === 'percent' ? z => z * 100 : undefined"
                :decimals="discountType === 'amount' ? 4 : 2">

                <div class="form-control-child discount-type-selector">
                  <wiskSelect :items="discountTypes" v-model="discountType" displayAsButtonGroup buttonGroupSize="xs" class="m-0" style="min-height: 0;" />
                </div>
              </wiskInput>
            </b-col>
          </b-row>
        </wiskInputGroup>
      </wiskModal>
    </div>
    <wiskInputGroup v-if="inline" class="h-auto px-0 wisk-price-input-inline" :class="{ 'no-bottom-margin-input': showPricePerUnit || showPreviousPrices }"
      @errorCountChanged="setValidState" :disabled="disabledLocal" :legend="showLabelIfInline ? (label || translations.txtGenericCost) : ''">
      <b-row noGutters :gap="0.5" class="flex-column" :class="{ 'flex-md-row': horizontal }">
        <b-col>
          <wiskInput noGutters infoTooltipKey="daf3dfbc-40e6-4614-bb8f-c96b339a3b74" :label="translations.txtGenericCost" v-model="priceValue" @update:modelValue="emitUpdateModel"
            inputType="number" :decimals="4" :minDecimals="2" decimalsAsNeeded @blur="priceValue && setOperation()" required :disabled="disabledLocal"
            :prefix="currency" :triggerInputOnLoad="triggerInputOnLoad" />
        </b-col>
        <b-col>
          <wiskSelect noGutters infoTooltipKey="7f32a655-a5f8-4b11-8f4b-36fe98f83b7b" :items="priceTypes" :disabled="!itemComputed || disabledLocal" v-model="selectedPriceType" required
            :label="translations.txtMovementEditPricePerUnitOrCase" :triggerInputOnLoad="triggerInputOnLoad" @change="setOperation()" />
        </b-col>
        <b-col v-if="discountAvailable">
          <wiskInput noGutters infoTooltipKey="4f4b91fb-3c40-407e-b6bb-68ae3da0465e" :label="translations.translate('tplMovementEditDiscountPerUnitOrCase', { '{a}': selectedPriceType || '' })"
            inputType="number" @blur="priceValue && setOperation()" @update:modelValue="emitUpdateModel" :disabled="!itemComputed || disabledLocal" fitChildren fitChildrenTight :fitChildrenCols="4" :key="discountType"
            :customFormatter="discountFormatter" v-model="discountValuesMap[discountType]" :decimals="discountType === 'amount' ? 4 : 2"
            :transformerIn="discountType === 'percent' ? z => z * 100 : undefined" :transformerOut="discountType === 'percent' ? z => z / 100 : undefined">

            <div class="form-control-child discount-type-selector">
              <wiskSelect :items="discountTypes" v-model="discountType" displayAsButtonGroup buttonGroupSize="xs" class="m-0" style="min-height: 0;" :disabled="disabled" />
            </div>
          </wiskInput>
        </b-col>
      </b-row>

      <priceCheck :item="itemComputed" showPricePerUnit showPreviousPrices :modelValue="modelValue" :currentPrice="currentPrice" :previousPrice="previousPrice" />
    </wiskInputGroup>

  </span>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import isEqual from 'lodash.isequal'
import { guid, prepareVariantIdAsItem, currencyFormatHideZero, currencyFormat } from '@/modules/utils'
import priceCheck from '@/components/bottles/ItemPriceCheck'

export default {
  name: 'ItemPrice',
  emits: ['valid', 'operation', 'input', 'update:modelValue', 'hide'],
  components: { priceCheck },
  props: {
    item: Object,
    caseSize: Number,
    itemId: Number,
    itemVariantId: Number,
    horizontal: Boolean,
    showPlainText: Boolean,
    required: Boolean,
    discountAvailable: Boolean,
    modelValue: Object,
    title: String,
    label: String,
    showPreviousPrices: Boolean,
    previousPrice: Number,
    showPricePerUnit: Boolean,
    movementId: { type: Number, default: 0 },
    operation: { type: String, default: 'price' },
    disabled: Boolean,
    inputClass: String,
    startOpened: Boolean,
    showLabelIfInline: Boolean,
    triggerInputOnLoad: Boolean,
    inline: Boolean,
    currentPrice: Number
  },
  data() {
    return {
      valid: true,
      inakesPopupOpen: false,
      oldValue: null,
      selectedPriceType: 'unit',
      discountTypes: [{ id: 'amount', title: '$' }, { id: 'percent', title: '%' }],
      discountType: 'amount',
      priceValue: 0, //no discount applied
      priceValueDiscounted: 0, //discount applied
      discountValuesMap: { amount: 0, percent: 0 },
      modalVisible: false,
      key: 1,
      disabledLocal: false,
      inputGroupParent: null,
      valueWatcherTimeoutId: null,
      currencyFormat,
      currencyFormatHideZero
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'itemVariantPricesById', 'itemPricesById', 'venue']),
    ...mapGetters(['measurementsByType', 'currency']),
    domId() {
      return 'z-' + (this.label || 'price-component').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    itemComputed() {
      if (this.item) {
        return this.item
      }
      if (this.itemVariantId) {
        return prepareVariantIdAsItem(this.itemVariantId, this.$store.state)
      }
      if (this.itemId) {
        return this.bottlesById[this.itemId]
      }
      return null
    },
    priceTypes() {
      let arr = []
      if (this.itemComputed?.measurement?.type && this.measurementsByType[this.itemComputed.measurement.type]?.units_of_measurements) {
        arr = this.measurementsByType[this.itemComputed.measurement.type].units_of_measurements.map(z => ({ ...z }))
      }

      if (this.itemComputed?.case_size > 1) {
        arr.unshift({ id: 'case', title: this.translations.txtGenericCase })
      }

      if (!arr.find(u => u.id === 'unit')) {
        arr.unshift({ id: 'unit', title: this.translations.txtGenericUnit })
        arr.unshift({ id: 'ea', title: this.translations.txtGenericEach })
      }

      return arr
    },
    computedValue() {
      return {
        type: this.selectedPriceType === 'case' || this.selectedPriceType === 'unit' ? this.selectedPriceType : 'manual',
        measurement: { quantity: 1, unit_of_measurement: this.selectedPriceType },
        case_size: this.caseSize || (this.itemComputed && this.itemComputed.case_size) || 1,
        value: this.priceValue,
        price_discount: { type: this.discountType, value: this.discountValuesMap[this.discountType] }
      }
    },
    formattedValue() {
      return `${currencyFormatHideZero(this.priceValueDiscounted || this.priceValue) || '-'} / ${this.selectedPriceType || '-'}`
    },
    discountFormattedValue() {
      if (this.discountValuesMap[this.discountType]) {
        return this.translations.translate('tplItemCostDiscountDisplay', { '{a}': currencyFormatHideZero(this.priceValue), '{b}': this.discountFormatter(this.discountValuesMap[this.discountType], true) })
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    discountFormatter(value, multiplyPercent) {
      if (this.discountType === 'amount') {
        return currencyFormatHideZero(value, this.currency, 4, true)
      }

      return (value && `${multiplyPercent ? value * 100 : value}%`) || ''
    },
    openMovement(id) {
      this.setGlobalAction({ type: 'movementEdit', action: { id } })
    },
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('valid', this.valid)

      if (this.required && this.inputGroupParent?.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.valid })
      }
    },
    setOperation(force) {
      if (!isEqual(this.modelValue, this.computedValue) || force) {
        let operation = { type: this.operation, value: { ...this.computedValue }, from: this.oldValue }

        if (this.inputGroupParent?.setOperation) {
          this.inputGroupParent.setOperation({
            operation,
            operationTypes: { set: this.operation }
          })
        }
        this.$emit('operation', operation)
        this.emitUpdateModel()
      }
    },
    emitUpdateModel() {
      setTimeout(() => {
        this.$emit('update:modelValue', { ...this.computedValue })
      }, 0)
    },
    reset() {
      if (this.oldValue) {
        this.selectedPriceType = this.oldValue.type === 'manual' ? this.oldValue.measurement.unit_of_measurement : this.oldValue.type
        this.priceValue = this.oldValue.value
        this.discountType = this.oldValue.price_discount.type
        this.discountValuesMap[this.discountType] = this.oldValue.price_discount.value
        this.key++
      }
      this.$emit('hide')
    }
  },
  mounted() {
    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }

    if (this.inputGroupParent) {
      this.disabledLocal = !!this.inputGroupParent.disabled

      if (this.inputGroupParent.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: false })
      }
    }
    this.disabledLocal = this.disabledLocal || this.disabled
    setTimeout(() => {
      if (this.triggerInputOnLoad) {
        this.setOperation(true)
      }
    }, 0)
  },
  beforeUnmount() {
    if (this.inputGroupParent?.setValidState) {
      this.inputGroupParent.setValidState({ name: this.domId, hasError: false })
    }
  },
  watch: {
    startOpened: {
      immediate: true,
      handler(startOpened) {
        if (startOpened) {
          this.modalVisible = startOpened
        }
      }
    },
    disabled() {
      this.disabledLocal = this.disabled
    },
    modalVisible(modalVisible) {
      if (!modalVisible) {
        this.$emit('hide')
      }
    },
    modelValue: {
      immediate: true,
      handler(modelValue) {
        if (modelValue) {
          this.quantity = modelValue.quantity || 0
          this.selectedPriceType = modelValue.type === 'manual' ? modelValue.measurement.unit_of_measurement : modelValue.type
          console.log(modelValue)
          this.priceValueDiscounted = modelValue.value_after_discount || 0
          this.priceValue = modelValue.value || 0

          if (modelValue.price_discount) {
            this.discountType = modelValue.price_discount.type
            this.discountValuesMap[this.discountType] = modelValue.price_discount.value
          } else if (modelValue.discount) {
            this.discountType = 'amount'
            this.discountValuesMap[this.discountType] = modelValue.discount
          }

          this.oldValue = { ...this.computedValue }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.discount-type-selector {
  border: 1px solid var(--bs-border-color);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .material-design-input-multiselect-as-buttons {
    min-width: 0;
  }
}

.wisk-price-input-inline {
  &.no-bottom-margin-input {
    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
