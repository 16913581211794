import get from 'lodash.get'
import { DateTime } from 'luxon'
import { formatDate, currencyFormatHideZero } from '@/modules/utils'

import agGridFilters from '@/modules/agGridFilters'

const gridOptions = {
  rowSelection: {
    mode: 'multiRow',
    checkboxes: true,
    isRowSelectable: node => get(node, 'data.delivery_status.pending') && node.data && !node.data.wiskRowHidden
  }
}

const getGridColumns = (translations, distributorsById, setGlobalAction) => [
  {
    headerName: translations.txtGenericYear,
    colId: 'year',
    wiskExpandFirst: true,
    sortOrder: 1800,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify({ colId: 'year', sort: params.value, title: params.value, id: params.value }),
    valueGetter: params => (params.data && params.data.date && params.data.date.getFullYear && params.data.date.getFullYear()) || ''
  },
  {
    headerName: translations.txtGenericMonth,
    colId: 'month',
    wiskExpandFirst: true,
    sortOrder: 1900,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    hide: true,
    keyCreator: params => JSON.stringify({ colId: 'month', sort: params.value + 1, title: translations[`txtGenericMonth${params.value}`] }),
    valueFormatter: params => translations[`txtGenericMonth${params.value}`],
    valueGetter: params => (params.data && params.data.date && params.data.date.getMonth && params.data.date.getMonth()) || 0
  },
  {
    headerName: translations.txtGenericWeek,
    colId: 'week',
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    enableRowGroup: true,
    hide: true,
    wiskExpandFirst: true,
    sortOrder: 2000,
    keyCreator: params => JSON.stringify(params.value),
    valueFormatter: params => params.value.title,
    valueGetter: params => {
      let dt = (params.data && params.data.date && DateTime.fromJSDate(params.data.date)) || null,
        week = (dt && dt.weekNumber) || 0,
        year = (week && params.data.date.getYear()) || 0,
        sort = parseInt(year.toString() + week.toString(), 10),
        title = (dt && `${translations.txtGenericWeek} ${week}, ${translations.txtGenericStartedOn} ${dt.startOf('week').toFormat('DD')}`) || ''

      return { colId: 'week', sort, title }
    }
  },
  {
    headerName: translations.txtGenericDate,
    colId: 'date',
    field: 'date',
    sort: 'desc',
    sortOrder: 2200,
    ...agGridFilters.date,
    valueFormatter: params => formatDate(params.value)
  },
  {
    headerName: translations.txtGenericTo,
    colId: 'to',
    sortOrder: 2300,
    valueGetter: params => {
      if (params.data && params.data.operation && params.data.operation.type === 'distributor_id' && distributorsById[params.data.operation.value]) {
        return `Distributor: ${distributorsById[params.data.operation.value].title}`
      }
      return ''
    }
  },
  {
    headerName: translations.txtGenericTotal,
    cellRendererParams: { decimals: 2 },
    sortOrder: 2400,
    cellClass: ['text-end', 'currency'],
    ...agGridFilters.number,
    valueFormatter: params => currencyFormatHideZero(params.value),
    field: 'dollars',
    colId: 'total'
  },
  {
    headerName: translations.txtPurchaseOrdersEmailSentStatus,
    colId: 'sentStatus',
    sortOrder: 2500,
    ...agGridFilters.text,
    enableRowGroup: true,
    valueGetter: params => (params.data && translations.groupPurchaseOrdersSentStatus[params.data.message_status.status]) || ''
  },
  {
    headerName: translations.txtPurchaseOrdersSMSSentStatus,
    colId: 'sentStatusSMS',
    sortOrder: 2530,
    ...agGridFilters.text,
    enableRowGroup: true,
    valueGetter: params => (params.data && translations.groupPurchaseOrdersSentStatus[params.data.sms_message_status.status]) || ''
  },
  {
    colId: 'createdBy',
    headerName: translations.txtGenericCreatedBy,
    minWidth: 140,
    width: 170,
    sortOrder: 2550,
    cellClass: [],
    enableRowGroup: true,
    hide: true,
    valueGetter: params => params.data && params.data.user && params.data.user.name
  },
  {
    colId: 'receivedStatus',
    headerName: translations.txtGenericReceivedStatus,
    cellRenderer: 'CellText',
    minWidth: 70,
    maxWidth: 140,
    cellClass: ['text-center', 'wrap'],
    ...agGridFilters.text,
    sortOrder: 2600,
    enableRowGroup: true,
    cellRendererParams: {
      translate: translations.translate,
      readonly: true
    },
    valueGetter: params => ({
      id: params.data && params.data.id,
      search_value: (params.data && params.data.delivery_status.status) || '',
      input_value: (params.data && translations[params.data.delivery_status.status]) || ''
    })
  },
  {
    colId: 'more',
    sortOrder: 27000,
    headerName: translations.columnDetails,
    minWidth: 100,
    width: 100,
    cellClass: ['cell-more-details'],
    suppressSortingToolPanel: true,
    suppressFiltersToolPanel: true,
    cellRenderer: 'CellMoreDetails',
    cellRendererParams: {
      excludeFromExport: true,
      onClick: id => {
        setGlobalAction({ type: 'purchaseOrderEdit', action: { id } })
      }
    },
    valueGetter: params => ({
      id: (params.data && (params.data.id || params.data.item_id)) || '',
      group: !!params.node.group
    })
  }
]

export { getGridColumns, gridOptions }
