<template>
  <b-container fluid class="">
    <b-row>
      <b-col>
        <wiskInputGroup style="min-height: 400px;" v-if="localValue">
          <wiskInput infoTooltipKey="8c85036b-d7e1-4c14-b05f-0989201aae76" :label="translations.txtGenericSubject" v-model="localValue.subject" />

          <wiskSelect infoTooltipKey="3da25d33-cc61-41bf-8aa8-b052dc23fb1a" :label="translations.txtGenericEmail"
            :tagPlaceholder="translations.txtPurchaseOrdersEmailPressEnter" v-model="emails" taggable multiple :tagValidator="emailValidator" />

          <gateKeeper feature="orders_sms" style="height: auto;" class="" smallBlock ref="gateKeeperCanSendSMS">
            <wiskSelect infoTooltipKey="1c962b68-0cc5-49f1-8607-766c783ab184" style="flex: 1;" :label="translations.txtGenericPhone" v-model="phoneNumbers" taggable multiple :tagValidator="phoneValidator"
              :tagPlaceholder="translations.txtPurchaseOrdersPhonePressEnter" />
          </gateKeeper>

          <wiskInput infoTooltipKey="7262c5bf-241a-42a3-a8e8-70254656085a" :label="translations.txtPurchaseOrdersNotes" multiline v-model="localValue.body" />

        </wiskInputGroup>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isValidEmail, isValidPhoneNumber } from '@/modules/utils'

export default {
  name: 'PurchaseOrderSend',
  emits: ['update:modelValue', 'validStateChanged'],
  components: {},
  props: {
    modelValue: null,
    purchaseOrder: Object
  },
  data() {
    return {
      key: '',
      localValue: null,
      emails: [],
      phoneNumbers: []
    }
  },
  computed: {
    ...mapState(['translations', 'venuesHighLevelById']),
    subject() {
      let venue = this.venuesHighLevelById[this.purchaseOrder.venue_id]
      return this.translations.translate('tplPurchaseOrdersEmailSubject', {
        '{a}': (venue && venue.title) || '',
        '{b}': this.purchaseOrder?.to?.distributor?.title || ''
      })
    }
  },
  methods: {
    ...mapActions([]),
    emailValidator(value) {
      return isValidEmail(value)
    },
    phoneValidator(value) {
      return isValidPhoneNumber(value)
    },
    callInput() {
      this.$emit('update:modelValue', { ...this.localValue })
    }
  },
  created() {
    this.localValue = { ...(this.modelValue || {}) }

    setTimeout(() => {
      if (this.purchaseOrder.to && this.purchaseOrder.to.distributor && Array.isArray(this.purchaseOrder.to.distributor.contacts)) {
        this.emails = this.purchaseOrder.to.distributor.contacts.map(c => c.email).filter(email => email && email.length)

        if (this.$refs.gateKeeperCanSendSMS && this.$refs.gateKeeperCanSendSMS.currentFeature && this.$refs.gateKeeperCanSendSMS.currentFeature.enabled) {
          this.phoneNumbers = this.purchaseOrder.to.distributor.contacts.map(c => c.phone).filter(phone => phone && phone.length > 5)
        }

        this.localValue.subject = this.subject
        this.localValue.body = ''
      }
    }, 0)
  },
  watch: {
    emails() {
      this.localValue.recipients = [...this.emails.map(value => ({ type: 'email', value })), ...this.phoneNumbers.map(value => ({ type: 'phone', value }))]
      this.$emit('validStateChanged', !!this.localValue.recipients.length)
    },
    phoneNumbers() {
      this.localValue.recipients = [...this.emails.map(value => ({ type: 'email', value })), ...this.phoneNumbers.map(value => ({ type: 'phone', value }))]
      this.$emit('validStateChanged', !!this.localValue.recipients.length)
    },
    subject() {
      this.localValue.subject = this.subject
    },
    localValue: {
      handler: 'callInput',
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>

</style>
