<template>
  <wiskModal v-model="visibleLocal" class="inventory-item-measurements-dialog" size="md" :title="translations.txtInventoriesEntriesEdit" hideFooter>
    <div style="min-height: 450px;" v-if="visibleLocal">
      <b-row class="mb-3" v-if="item">
        <b-col cols="4" class="">
          <imageView :src="item.image" filterDisplay="small" />
        </b-col>
        <b-col cols="8" class="">
          <h5 :class="{ 'bg-warning': item.archived }" class="px-3 mt-3"> {{ item.title + item.titleSuffix }}
            <b-button variant="link" class="" size="sm" @click="setGlobalAction({ type: 'itemEdit', action: { item_id: localItemId, variantId: item.item_distributor_id } })">
              <icon name="wisk-edit" scale=".7"></icon>
            </b-button>
          </h5>
          <h6 v-if="item.archived" class="py-2">
            <icon name="wisk-information-button" class="text-primary" scale=".7" />
            {{ translations.txtInventoriesArchivedWarning }}
          </h6>
          <b-badge v-if="submittedTracker && submittedTracker[item.item_distributor_id]"
            style="opacity: 0.8;" class="text-white" variant="success">
            {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[item.item_distributor_id] }}
          </b-badge>
        </b-col>
      </b-row>
      <div v-else>
        <wiskInput infoTooltipKey="124ed237-51c3-4c72-aa3c-aae3581d0a78" autofocus :label="translations.txtGenericWiskItem"
          :placeholder="translations.txtGenericTypeToSearch" autocomplete @autocompleteInput="onAutocompleteInput" @clear="onAutocompleteSelected(null)"
          @autocompleteSelected="onAutocompleteSelected" :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix" :autocompleteItemExists="!!item"
          autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" required fitChildren :fitChildrenCols="4">

          <wiskInput infoTooltipKey="itemsSearchAutoCompleteShowArchived" :label="translations.txtServingSizesShowArchived" v-model="showArchived" inputType="checkbox" />
        </wiskInput>

        <b-button class="d-block mt-2 mx-auto" variant="outline-primary" size="sm" @click="openCreateItemDialog">
          {{ translations.txtVenueBottlesCreate }}
        </b-button>
      </div>

      <div v-if="item" style="width: 350px;" class="mx-auto">
        <b-row v-for="entry in localEntries" :key="entry.id" class="item-view-mini mt-2 inventory-item-measurement">
          <b-col cols="10">
            <inventoryItemMeasurement hideButtons v-model="entriesById[entry.id]" :hideTypeSelector="addEntryId !== entry.id" class="px-5" @update:modelValue="changeTracker[entry.id] = true"
              :variantId="entry.measurement.item_distributor_id" @itemFieldChange="$emit('itemFieldChange', $event)" emitFast />
          </b-col>
          <b-col cols="2" class="">
            <b-button variant="link" @click="removeEntry(entry.id)" class="text-primary text-danger-hover mt-0 px-0 float-end inventory-item-measurement-buttons" v-tooltip="translations.txtGenericRemove">
              <icon name="wisk-exit" :scale="0.7" />
            </b-button>
            <b-button variant="link" @click="onEntryChange(entriesById[entry.id])" class="text-primary text-success-hover mt-2" v-tooltip="translations.txtGenericSave" :disabled="!changeTracker[entry.id]">
              <icon name="wisk-check" :scale="1.4" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="w-100 text-center mt-5" v-if="availableVariants">
        <b-button v-if="availableVariants.length === 1" class="d-block mx-auto mt-5" @click="addNewEntry(item.item_distributor_id)"
          size="sm" :disabled="!!addEntryId || !item" style="" variant="outline-primary">
          {{ translations.txtInventoriesEntryAdd }}
        </b-button>

        <b-dropdown up :disabled="!!addEntryId || !item" v-if="availableVariants.length > 1" size="sm" variant="link"
          toggle-class="text-decoration-none pt-0 d-block" class=" mx-auto" no-caret>
          <template v-slot:button-content>
            <b-button class="" size="sm">
              {{ translations.txtInventoriesEntryAdd }}
            </b-button>
          </template>
          <b-dropdown-item>
            <b-button @click="addVariant" size="sm" block>
              {{ translations.txtVenueBottlesCreateVariant }}
            </b-button>
          </b-dropdown-item>
          <div class="dropdown-divider" />
          <b-dropdown-item v-for="variant in availableVariants" :key="variant.id" @click="addNewEntry(variant.id)" :active="variant.id === item.item_distributor_id">
            {{ variant.title }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <wiskLoading :loading="loading" />
    </div>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { arrayToObjectById, objectFilter, prepareVariantIdAsItem, prepareVariantAsItem } from '@/modules/utils'
import inventoryItemMeasurement from '@/components/inventories/InventoryItemMeasurement'
import imageView from '@/components/common/ImageView'

let getId = () => new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)

export default {
  name: 'InventoryItemMeasurementsDialog',
  emits: ['remove', 'visible', 'itemSelected', 'itemFieldChange', 'add', 'edit'],
  components: { inventoryItemMeasurement, imageView },
  props: {
    inventoryId: Number,
    itemId: Number,
    locationId: Number,
    inventoryEntries: { type: Array, required: true },
    submittedTracker: Object,
    visible: Boolean
  },
  data() {
    return {
      autocompleteItems: [],
      localItemId: null,
      visibleLocal: false,
      entriesById: null,
      addEntryId: null,
      localEntries: [],
      items: [],
      showArchived: false,
      item: null,
      changeTracker: {},
      loading: false
    }
  },
  computed: {
    ...mapState(['user', 'translations', 'itemVariants', 'itemVariantsById', 'bottlesById']),
    ...mapGetters(['venue']),
    availableVariants() {
      if (this.localItemId) {
        return this.itemVariants.filter(v => v && !v.archived && v.inventoriable && v.item_id === this.localItemId)
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    addSuffix(text, item) {
      return text + item.titleSuffix
    },
    onAutocompleteInput(value) {
      this.autocompleteItems = this.items.filter(b => (!b.archived || this.showArchived) && objectFilter({ payload: b, query: value }))
    },
    openCreateItemDialog() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          creationSource: { type: 'inventory', id: this.inventoryId },
          onChange: item => {
            this.localItemId = item.item_id
            item.item_distributor_id = item.item_distributor_ids[0]
            this.$emit('itemSelected', this.localItemId)
          }
        }
      })
    },
    onAutocompleteSelected(item) {
      if (item) {
        this.localItemId = item.item_id
        this.autocompleteItems = []
        this.$emit('itemSelected', this.localItemId)
      } else {
        this.localItemId = null
        this.autocompleteItems = []
      }
    },
    onEntryChange(entry) {
      let type = get(entry, 'measurement.measurement.type'),
        value = 0,
        quantity = get(entry, 'measurement.measurement.quantity') || 0,
        mlLeft = get(entry, 'measurement.measurement.ml_left') || 0,
        percentageRemaining = get(entry, 'measurement.measurement.percentage_remaining') || 0

      switch (type) {
        case 'scale':
          value = mlLeft
          break
        case 'slider':
          value = percentageRemaining
          break
        default:
          value = quantity
          break
      }

      this.changeTracker[entry.id] = false

      if (value) {
        let eventType = (this.addEntryId && 'add') || 'edit'

        this.addEntryId = null
        this.$emit(eventType, entry)
        this.loading = true
      }
    },
    removeEntry(id) {
      this.$emit('remove', merge({}, this.entriesById[id]))
      delete this.entriesById[id]
      this.localEntries = Object.values(this.entriesById)

      if (id === this.addEntryId) {
        this.addEntryId = null
      }
    },
    getVariantIdFromItemId(itemId) {
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    addNewEntry(variantId) {
      console.log('variantId', variantId)
      let id = getId(),
        item = variantId ? prepareVariantIdAsItem(variantId, this.$store.state) || {} : this.item || {},
        entry = {
          id,
          location_id: this.locationId,
          inventory_order: id,
          measurement: {
            measurement: { type: 'unit', quantity: 0 },
            timestamp: new Date().toISOString(),
            item_id: this.localItemId,
            item_distributor_id: variantId || this.getVariantIdFromItemId(item.item_id),
            order: id,
            item_measurement: item.measurement,
            item_distributor_measurement: item.measurement,
            user_id: this.user.id
          },
          venue_id: this.venue.id,
          inventory_id: this.inventoryId,
          user_id: this.user.id
        }
      this.addEntryId = id
      this.entriesById[id] = entry
      this.localEntries = [...this.localEntries, entry]
    },
    loadLocalEntries() {
      let found = (this.addEntryId && this.entriesById[this.addEntryId]) || null,
        entries = this.inventoryEntries.map(i => merge({}, i))

      if (found) {
        entries.push(merge({}, found))
      }
      this.localEntries = [...entries]
    },
    addVariant() {
      this.setGlobalAction({
        type: 'itemVariantEdit',
        action: {
          id: 0,
          itemId: this.localItemId,
          onChange: result => {
            setTimeout(() => {
              if (this.itemVariantsById[result.id]) {
                this.addNewEntry(result.id)
              } else {
                setTimeout(() => {
                  this.addNewEntry(result.id)
                }, 2000)
              }
            }, 2000)
          }
        }
      })
    }
  },
  watch: {
    itemVariants: {
      immediate: true,
      handler() {
        this.items = this.itemVariants.filter(v => v && !v.archived && v.inventoriable).map(v => ({ ...prepareVariantAsItem(v, this.$store.state) }))
      }
    },
    localEntries: {
      immediate: true,
      handler(entries) {
        this.entriesById = arrayToObjectById(entries)
      }
    },
    inventoryEntries: {
      immediate: true,
      handler: 'loadLocalEntries'
    },
    localItemId: {
      immediate: true,
      handler(localItemId) {
        if (localItemId && this.bottlesById[localItemId]) {
          console.log('localItemId', localItemId)
          this.item = this.bottlesById[localItemId]
          console.log('this.item ', this.item)
          this.item.item_distributor_id = (this.item && this.item.item_distributor_ids && this.item.item_distributor_ids[0]) || null
        } else {
          this.item = null
        }
      }
    },
    itemId: {
      immediate: true,
      handler(itemId) {
        this.localItemId = itemId
      }
    },
    submittedTracker() {
      this.loading = false
    },
    visible: {
      immediate: true,
      handler(visible) {
        this.addEntryId = null
        this.visibleLocal = !!visible

        if (visible && this.item) {
          if (!this.inventoryEntries.length) {
            this.addNewEntry()
          }
          if (this.inventoryEntries && this.inventoryEntries.length === 1) {
            let entry = this.inventoryEntries[0],
              foundValue =
                get(entry, 'measurement.measurement.quantity', 0) + get(entry, 'measurement.measurement.percentage_remaining', 0) + get(entry, 'measurement.measurement.ml_left', 0)

            if (!foundValue) {
              this.addEntryId = entry.id
            }
          }
        }
      }
    },
    visibleLocal: {
      immediate: true,
      handler(visibleLocal) {
        this.$emit('visible', visibleLocal)
      }
    }
  }
}
</script>

<style lang="scss">
.inventory-item-measurements-dialog {

  .inventory-item-measurement {

    .inventory-item-measurement-buttons {
      display: block;
      opacity: 0;
    }

    &:hover {
      .inventory-item-measurement-buttons {
        opacity: 1;
      }
    }

  }
}
</style>
