<template>
  <b-container fluid class="inventory-pair-edit">
    <b-row v-if="initDone">
      <b-col md="6" cols="12" class="px-0">
        <b-card no-body class="mx-1">
          <b-card-header header-tag="header" class="pt-1 pb-0" style="min-height: 65px;">
            <b-row>
              <b-col md="5" cols="12" class="pe-0 mt-1 pe-3-m">
                <wiskSelect infoTooltipKey="204d157b-aa9c-4623-857d-98fc57ad0a3e" :label="translations.txtGenericView" v-if="previousInventoryId" :items="viewModes" v-model="viewMode" class="mb-1" :multiselectOptions="{ allowEmpty: false }" />
              </b-col>
              <b-col md="4" cols="6" class="pe-0 pb-1 mt-1">
                <wiskInput infoTooltipKey="79dba1ec-0e39-48e7-a319-d96c6a2869bc" class="mb-0" inputClass="py-0" :label="translations.txtGenericTypeToSearch" v-model="itemsSearchQuery">
                  <b-button title="Clear" variant="link" @click="itemsSearchQuery = ''" v-show="itemsSearchQuery" style="top: -31px; right: 12px; position: absolute;">
                    <icon class="text-primary" :scale="0.8" name="wisk-exit" />
                  </b-button>
                </wiskInput>
              </b-col>
              <b-col md="3" cols="6" class="ps-1 pb-0">
                <b-button class="float-end mt-2" variant="outline-primary" size="sm" @click="openCreateItemDialog">
                  {{ translations.txtVenueBottlesCreate }}
                </b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end" v-if="(['previousInventory', 'previousInventoryWithMovements']).includes(viewMode) && locationId && !currentInventoryMeasurements.length">
              <infoTooltip v-if="previousInventoryArchivedItems.length > 1" helpKey="0d94062c-3b33-48e3-bb25-8a034cd4e53d" />
              <b-button class="text-start p-0" variant="link" @click.stop="submitAllFromPreviousLocation" :disabled="previousInventoryArchivedItems.length > 1">
                <span :class="{ 'text-warning': previousInventoryArchivedItems.length === 1 }">
                  {{ translations.txtInventoriesSubmitAll }}
                  <icon v-if="previousInventoryArchivedItems.length <= 1" :scale="0.7" name="wisk-angle-double-right" class="ms-1" />
                </span>
              </b-button>
            </div>
          </b-card-header>
          <b-card-body v-if="viewMode === 'items'" style="height: calc(100vh - 245px); overflow: auto;" class="p-2">
            <wiskLoading v-if="mapItemsToCategoriesInProgress" loading :timeout="2200" style="position: relative;">
              <template v-slot:display-after-timeout>
                <p> {{ translations.txtGenericEmptyList }} </p>
              </template>
            </wiskLoading>
            <b-accordion :items="itemsByCategory" headerKey="title" v-model="categoryAccordion">

              <template v-slot="category">
                <div v-if="category.items.length">
                  <itemViewMini v-for="item in category.items" :key="item.item_distributor_id" :variantId="item.item_distributor_id">
                    <inventoryItemMeasurement class="" @submit="addItem(item)" v-model="itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id]" emitFast
                      @itemFieldChange="onItemFieldChange" :variantId="item.item_distributor_id" showOpenEditButton @open="openSelectedEntriesDialog({ measurement: { item_id: item.item_id } })">

                      <b-badge v-if="submittedTracker[item.item_distributor_id]" style="position: absolute; bottom: -5px; left: 10px; opacity: 0.8;" class="text-white" variant="success">
                        {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[item.item_distributor_id] }}
                      </b-badge>
                    </inventoryItemMeasurement>
                  </itemViewMini>
                </div>
                <div v-else>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </div>
              </template>
            </b-accordion>
          </b-card-body>
          <b-card-body style="height: calc(100vh - 245px); overflow: auto;" class="p-2"
            v-if="(['previousInventory', 'previousInventoryWithMovements', 'previousInventoryHidden']).includes(viewMode) && previousInventoryMeasurements && previousInventoryId && locationId">
            <div v-if="previousInventoryMeasurements.length">
              <itemViewMini v-for="entry in previousInventoryMeasurements" :key="entry.id" :variantId="entry.measurement.item_distributor_id">
                <inventoryItemMeasurement @submit="addEntry(previousInventoryMeasurementsByEntryId[entry.id])" v-model="previousInventoryMeasurementsByEntryId[entry.id]" hideTypeSelector
                  showHideToggleButton :entryHidden="hiddenTrackerByPreviousEntryId[entry.measurement.order]" @toggleHidden="toggleHidden(entry)" :variantId="entry.measurement.item_distributor_id"
                  @itemFieldChange="onItemFieldChange" showOpenEditButton @open="openSelectedEntriesDialog(previousInventoryMeasurementsByEntryId[entry.id])">

                  <b-badge v-if="submittedTracker[entry.measurement.item_distributor_id]" style="position: absolute; bottom: -5px; left: -100%; opacity: 0.8;" class="text-white" variant="success">
                    {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[entry.measurement.item_distributor_id] }}
                  </b-badge>
                </inventoryItemMeasurement>
              </itemViewMini>
            </div>
            <div v-else>
              <wiskLoading loading :timeout="2200" style="position: relative;">

                <template v-slot:display-after-timeout>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </template>
              </wiskLoading>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6" cols="12" class="px-0">
        <b-card no-body class="mx-1">
          <b-card-header header-tag="header" class="pt-2 pb-1" style="min-height: 65px;">
            <b-row>
              <b-col class="flex-grow-0">
                <h5>{{ translations.txtGenericSummary }}</h5>
              </b-col>
              <b-col class="d-flex flex-wrap flex-grow-1 justify-content-end align-items-center">
                <wiskInput infoTooltipKey="589cb78f-dd04-474e-bf84-eafe1aaf5a8c" class="mb-0" inputClass="py-0" :label="translations.txtGenericTypeToSearch" v-model="currentItemsSearchQuery">
                  <b-button title="Clear" variant="link" @click="currentItemsSearchQuery = ''" v-show="currentItemsSearchQuery" style="top: -31px; right: 12px; position: absolute; z-index: 1;">
                    <icon class="text-primary" :scale="0.8" name="wisk-exit"></icon>
                  </b-button>
                </wiskInput>
                <b-button variant="outline-primary" size="sm" @click="openSelectedEntriesDialog({ measurement: { item_id: null } })"
                  v-tooltip="translations.txtInventoriesSearchAddQuick" class="float-end px-1 ms-1">
                  <icon class=" me-2" :scale="0.8" name="wisk-zoom-in"></icon>
                  <span> {{ translations.txtInventoriesEntryAdd }} </span>
                </b-button>
                <b-button v-if="Object.entries(entriesToMove).length" variant="outline-primary" size="sm" @click="moveEntriesDialog = true"
                  v-tooltip="translations.txtInventoriesEntriesMoveToOther" class="float-end px-1 ms-1">
                  <span> {{ translations.txtInventoriesEntriesMove }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>

          <b-card-body style="height: calc(100vh - 245px); overflow: auto;" class="p-2">
            <div v-show="currentVisibleInventoryMeasurements.length">

              <itemViewMini :variantId="entry.measurement.item_distributor_id || 999" v-for="(entry, index) in currentVisibleInventoryMeasurements" :key="entry.id"
                class="inventory-item-measurement">
                <b-row>
                  <b-col cols="10" class="px-0-m">
                    <b-row>
                      <b-col>
                        <inventoryItemMeasurement @update:modelValue="updateEntry" hideTypeSelector :modelValue="entry" :variantId="entry.measurement.item_distributor_id" hideButtons />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2" class="px-0-m position-relative">
                    <b-button variant="link" @click="removeEntry(entry)" class="text-primary text-danger-hover px-0-m inventory-item-measurement-buttons"
                      v-tooltip="translations.txtGenericRemove" style="position: absolute; right: 5px; top: calc(50% - 45px);">
                      <icon class="" :scale="0.7" name="wisk-exit"></icon>
                    </b-button>
                    <b-button variant="link" @click.exact="toggleMoveEntry(entry)" @click.shift.exact="toggleMoveEntry(entry, true, index)"
                    class="text-primary pe-0-m inventory-item-measurement-buttons" :class="{ 'persist': entriesToMove[entry.id] }"
                      v-tooltip="translations.txtInventoriesEntryMove" style="position: absolute; right: 5px; top: calc(50% - 13px);">
                      <icon v-if="entriesToMove[entry.id]" class="" :scale="1" name="wisk-check-box"></icon>
                      <icon v-else class="" :scale="1" name="wisk-empty-box"></icon>
                    </b-button>
                    <b-button size="sm" variant="link" @click="openSelectedEntriesDialog(entry)" class="me-1 pe-0-m inventory-item-measurement-buttons" v-tooltip="translations.txtInventoriesEditDetails"
                      style="position: absolute; right: 5px; top: calc(100% - 20px);">
                      <icon class="" style="margin-top: -1px;" scale="1" name="wisk-more-info"></icon>
                    </b-button>
                  </b-col>
                </b-row>
              </itemViewMini>
            </div>
            <div v-if="!currentVisibleInventoryMeasurements.length" style="height: 150px;" class="fill-width-height">
              <wiskLoading loading :timeout="1000">

                <template v-slot:display-after-timeout>
                  <p> {{ translations.txtGenericEmptyList }} </p>
                </template>
              </wiskLoading>
            </div>
            <b-button class="d-block w-100 py-3" variant="link" size="sm" @click="openSelectedEntriesDialog({ measurement: { item_id: null } })"
              v-tooltip="translations.txtInventoriesSearchAddQuick" id="bt-add-item-quick-scroll-target">
              <icon class="text-primary" :scale="1.3" name="wisk-zoom-in"></icon>
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <wiskLoading loading />
    </b-row>

    <inventoryItemMeasurementsDialog :visible="inventoryItemMeasurementsDialogVisible" @visible="inventoryItemMeasurementsDialogVisible = $event" @itemSelected="openSelectedEntriesDialog({ measurement: { item_id: $event } })"
      :inventoryEntries="selectedInventoryEntries" :itemId="selectedItemId" :submittedTracker="submittedTracker" :inventoryId="inventoryId" :locationId="locationId"
      @itemFieldChange="onItemFieldChange" @edit="updateEntry" @add="addEntry($event, 'search')" @remove="removeEntry" :key="inventoryItemMeasurementsDialogKey" />

    <wiskModal :visible="!!selectedInventoryEntry" @hide="selectedInventoryEntry = null" size="md" hideFooter :title="translations.txtInventoriesEntryEdit">
      <div style="height: 200px;">
        <itemViewMini v-if="selectedInventoryEntry" :variantId="selectedInventoryEntry.measurement.item_distributor_id || 555">
          <inventoryItemMeasurement @submit="editSelectedEntry" v-model="selectedInventoryEntry" class="mt-2" :variantId="selectedInventoryEntry.measurement.item_distributor_id" @itemFieldChange="onItemFieldChange">
            <b-badge v-if="submittedTracker[selectedInventoryEntry.measurement.item_distributor_id]" style="position: absolute; bottom: -5px; left: -100%; opacity: 0.8;" class="text-white" variant="success">
              {{ translations.txtInventorySubmittedValue }}: {{ submittedTracker[selectedInventoryEntry.measurement.item_distributor_id] }}
            </b-badge>
          </inventoryItemMeasurement>
        </itemViewMini>
      </div>
    </wiskModal>

    <wiskModal :visible="moveEntriesDialog" @hide="moveEntriesDialog = false" size="md" :title="translations.txtInventoriesEntriesMoveTo"
      :okDisabled="!selectedInvetoryIdToMoveTo || !selectedAreaToMoveTo" @ok="handleMovingEntries" preventOKClose>
      <div style="height: 400px;">
        <wiskSelect infoTooltipKey="5f2d9858-9064-4c03-9bad-ba7985237207" :label="translations.txtGenericInventory" :items="visibleInventories" v-model="selectedInvetoryIdToMoveTo" @select="selectedAreaToMoveTo = null" class="mb-3" required>
          <template v-slot:afterList>
            <b-button v-if="!inventoriesHighLevelLoadAllComplete" variant="primary" class="m-1" @click="getInventories">
              {{ translations.txtInventoryLoadAll }}
            </b-button>
          </template>
        </wiskSelect>
        <wiskSelect :disabled="!selectedInvetoryIdToMoveTo" infoTooltipKey="fe9e0c87-1bab-486a-b34f-6a173b372254" titleKey="location.title" trackBy="location_id" :label="translations.txtGenericLocation" :items="inventoryLocationsByInventoryId[selectedInvetoryIdToMoveTo]" v-model="selectedAreaToMoveTo" class="mb-1" required />
        <hr class="mt-4 mb-2 border-secondary" />
        <h6>{{ translations.txtInventoriesEntriesToMove }}</h6>
        <itemViewMini v-for="entry in Object.values(entriesToMove)" :key="'reivew' + entry.id" :editable="false" :variantId="entry.measurement.item_distributor_id || 999">
          <inventoryItemMeasurement @update:modelValue="updateEntry" hideTypeSelector :modelValue="entry" :variantId="entry.measurement.item_distributor_id" hideButtons :editable="false" />
        </itemViewMini>
      </div>
    </wiskModal>

    <confirm ref="confirmDialogArchivedItems" hideCancel />
    <confirm ref="confirmDialog" autofocus />
  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import api from '@/api'
import { arrayToObjectById, objectFilter, round, prepareVariantAsItem, prepareVariantIdAsItem, compareNumbers, formatDate } from '@/modules/utils'
import itemViewMini from '@/components/bottles/ItemViewMini'
import inventoryItemMeasurement from '@/components/inventories/InventoryItemMeasurement'
import inventoryItemMeasurementsDialog from '@/components/inventories/InventoryItemMeasurementsDialog'

let getId = () => new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)

export default {
  name: 'InventoryPair',
  emits: ['refresh', 'refreshEntry'],
  components: { itemViewMini, inventoryItemMeasurement, inventoryItemMeasurementsDialog },
  props: { inventoryId: Number, locationId: Number, inventoryData: Object, entries: Array },
  data() {
    return {
      debug: 0,
      selectedInventoryEntry: null,
      selectedInventoryEntries: [],
      selectedItemId: null,
      inventoryItemMeasurementsDialogVisible: false,
      initDone: false,
      initDoneCurrent: false,
      initDonePrevious: false,
      submittedTracker: {},
      inventoryItemMeasurementsDialogKey: 1,
      itemsSearchQuery: '',
      currentItemsSearchQuery: '',
      currentItemsSearchTimeoutId: null,
      viewMode: 'previousInventory',
      mapItemsToCategoriesInProgress: false,
      unsubscribers: [],
      itemsByCategory: [],
      itemsByCategoryMeasurementsByItemVariantId: {},
      previousInventoryMeasurements: [],
      previousInventoryArchivedItems: [],
      previousInventoryMeasurementsByEntryId: {},
      currentInventoryMeasurements: [],
      submittedTrackerByPreviousEntryId: {},
      hiddenTrackerByPreviousEntryId: {},
      categoryAccordion: '',
      inventoryPairData: null,
      items: [],
      entriesToMove: {},
      moveEntriesDialog: false,
      selectedInvetoryIdToMoveTo: null,
      selectedAreaToMoveTo: null
    }
  },
  computed: {
    ...mapState(['venue', 'translations', 'bottlesById', 'families', 'locations', 'itemVariants', 'categories', 'categoriesById', 'familiesById', 'currentPermissionsByType', 'locationsById', 'user', 'recentInventoriesById', 'inventories', 'inventoryLocationsByInventoryId', 'inventoriesHighLevelLoadAllComplete']),
    ...mapGetters(['currency']),
    currentVisibleInventoryMeasurements() {
      if (this.currentItemsSearchQuery) {
        //if there will be performance issues, we can generate the search field upfront and use stringFilter instead of objectFilter
        return this.currentInventoryMeasurements.filter(entry => {
          let variantId = get(entry, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(entry, 'measurement.item_id'), 'loadPreviousMeasurements 645')),
            item = prepareVariantIdAsItem(variantId, this.$store.state)

          if (item) {
            return objectFilter({ payload: item, query: this.currentItemsSearchQuery, stopAtLevel: 2 })
          }
          return false
        })
      }

      return this.currentInventoryMeasurements
    },
    visibleInventories() {
      return this.inventories.filter(i => !i.archived).map(i => ({
        ...i,
        title: formatDate(i.started_at),
        $isDisabled: i.is_locked
      }))
    },
    previousInventoryId() {
      return get(this.inventoryPairData, 'previous.id')
    },
    viewModes() {
      return [
        { id: 'previousInventory', title: 'Previous inventory' },
        { id: 'previousInventoryWithMovements', title: 'Previous inventory + movements' },
        { id: 'previousInventoryHidden', title: 'Hidden items from previous inventory' },
        { id: 'items', title: 'Search All Venue Items' }
      ]
    },
    activeItems() {
      return this.items.filter(item => item && !item.archived && item.variant && !item.variant.archived)
    },
    filteredItems() {
      if (this.itemsSearchQuery) {
        return this.activeItems.filter(item => objectFilter({ payload: item, query: this.itemsSearchQuery }))
      }
      return this.activeItems
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateBottle', 'setItemVariant', 'getInventories']),
    toggleMoveEntry(entry, group, index) {
      const edited = this.entriesToMove
      if (group) {
        this.currentVisibleInventoryMeasurements.forEach((e, i) => {
          if (i <= index) {
            if (this.entriesToMove[entry.id]) {
              delete edited[e.id]
            } else {
              edited[e.id] = e
            }
          }
        })
      } else {
        if (this.entriesToMove[entry.id]) {
          delete edited[entry.id]
        } else {
          edited[entry.id] = entry
        }
      }
      this.entriesToMove = { ...edited }
    },
    handleMovingEntries() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.moveEntriesDialog = false
            api.moveMeasurementsAreas({
              from_inventory_id: this.inventoryId,
              to_inventory_id: this.selectedInvetoryIdToMoveTo,
              from_location_id: this.locationId,
              to_location_id: this.selectedAreaToMoveTo,
              measurement_ids: Object.values(this.entriesToMove).map(e => e.measurement.measurement_id)
            })
            this.entriesToMove = {}
          },
          message: this.translations.txtInventoriesEntriesMoveConfirmation,
          title: this.translations.txtInventoriesEntriesMove
        })
      }
    },
    saveInventoryLocation(operation, locationId = this.locationId, inventoryId = this.inventoryId) {
      api.updateInventoryLocation(inventoryId, { locationId, operation })
    },
    onItemFieldChange({ itemId, variantId, operation }) {
      if (variantId) {
        this.setItemVariant({ id: variantId, operation })
      } else {
        this.updateBottle({ id: itemId, operation })
      }
    },
    toggleHidden(entry) {
      this.hiddenTrackerByPreviousEntryId[entry.measurement.order /* this "order" word should be id actually*/] = !this.hiddenTrackerByPreviousEntryId[entry.measurement.order]
      this.hiddenTrackerByPreviousEntryId = { ...this.hiddenTrackerByPreviousEntryId }
    },
    addItem(item) {
      let measurement = this.itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id].measurement
      let id = getId(),
        entry = {
          location_id: this.locationId,
          inventory_order: id,
          measurement: {
            ...measurement,
            timestamp: new Date().toISOString(),
            item_distributor_id: item.item_distributor_id,
            item_id: item.item_id,
            order: id,
            item_distributor_measurement: item.measurement,
            user_id: this.user.id
          },
          venue_id: this.venue.id,
          inventory_id: this.inventoryId,
          user_id: this.user.id
        }

      this.addEntry(entry, 'search')
      measurement.measurement.quantity = 0
      this.itemsByCategoryMeasurementsByItemVariantId[item.item_distributor_id] = { measurement }
    },
    openCreateItemDialog() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          creationSource: { type: 'inventory', id: this.inventoryId },
          onChange: item => {
            let id = getId()
            this.selectedInventoryEntry = {
              item,
              measurement: {
                timestamp: new Date().toISOString(),
                item_id: item.item_id,
                item_distributor_id: item.item_distributor_ids[0],
                measurement: { type: 'unit', quantity: 0 },
                order: id,
                item_distributor_measurement: item.measurement,
                user_id: this.user.id
              },
              id,
              inventory_order: id,
              inventory_id: this.inventoryId,
              location_id: this.locationId,
              venue_id: this.venue.id,
              user_id: this.user.id
            }
          }
        }
      })
    },
    openSelectedEntriesDialog(entry) {
      if (this.currentInventoryMeasurements) {
        let found = this.currentInventoryMeasurements.filter(e => e.measurement.item_id === entry.measurement.item_id)

        this.selectedItemId = entry.measurement.item_id
        this.selectedInventoryEntries = found.map(e => ({ ...e }))

        this.inventoryItemMeasurementsDialogVisible = true
      }
    },
    editSelectedEntry() {
      this.addEntry(this.selectedInventoryEntry, 'summary')
      setTimeout(() => {
        this.selectedInventoryEntry = null
      }, 0)
    },
    addEntry(entry, inputSource = 'previous_inventory') {
      entry = merge({}, entry) //important! something clears the quantity from measurement, to be investigated

      if (
        entry?.measurement?.measurement &&
        (entry.measurement.measurement.quantity || entry.measurement.measurement.quantity === 0 || entry.measurement.measurement.percentage_remaining || entry.measurement.measurement.ml_left)
      ) {
        delete entry.measurement.measurement.value
        delete entry.item

        let item = prepareVariantIdAsItem(entry.measurement.item_distributor_id, this.$store.state) || {}
        entry.measurement.measurement.case_size = item.case_size

        let order = getId(),
          measurement = {
            ...entry.measurement,
            inventory_id: this.inventoryId,
            inventory_order: order,
            id: order,
            case_size: item.case_size,
            order,
            measurement_id: order,
            previous_inventory_order: entry.measurement.order
          },
          operation = {
            type: 'item_add',
            value: {
              item_id: entry.measurement.item_id,
              item_distributor_id: entry.measurement.item_distributor_id,
              measurement_id: order,
              order,
              id: order,
              item_distributor_measurement: item.measurement,
              user_id: this.user.id,
              input_source: {
                type: inputSource
              },
              previous_inventory_order: entry.measurement.order,
              timestamp: new Date().toISOString(),
              hidden: false,
              ...measurement
            }
          },
          newEntry = merge({}, entry, {
            inventory_order: order,
            inventory_id: this.inventoryId,
            id: order,
            measurement
          })

        if (inputSource === 'previous_inventory') {
          this.toggleHidden(entry)
        }

        this.$emit('refreshEntry', newEntry)

        this.saveInventoryLocation(operation)

        setTimeout(() => {
          if (this.inventoryItemMeasurementsDialogVisible) {
            this.openSelectedEntriesDialog(newEntry)
          }
        }, 3000)
      }
    },
    updateEntry: debounce(function debounceHandler(e) {
      let entry = cloneDeep(e)
      console.log('-+-+-+-+-+-+-+-+-+-+-+ updateEntry', entry)

      if (entry?.measurement?.measurement &&
        (entry.measurement.measurement.quantity || entry.measurement.measurement.quantity === 0 || entry.measurement.measurement.percentage_remaining || entry.measurement.measurement.ml_left)
      ) {
        delete entry.measurement.measurement.value
        delete entry.units_computed

        if (entry.id) {
          this.$emit('refreshEntry', entry)
        }

        let operation = {
          type: 'item_update',
          value: {
            item_id: entry.measurement.item_id,
            item_distributor_id: entry.measurement.item_distributor_id || null,
            order: entry.measurement.order,
            measurement_id: entry.id,
            item_distributor_measurement: entry.measurement.item_distributor_measurement || null,
            user_id: this.user.id,
            input_source: {
              type: 'previous_inventory'
            },
            previous_inventory_order: entry.measurement.previous_inventory_order || null,
            timestamp: new Date().toISOString(),
            hidden: false,
            measurement: { ...entry.measurement.measurement }
          }
        }

        this.saveInventoryLocation(operation)
      }
    }, 1000),
    removeEntry(entry) {
      delete this.hiddenTrackerByPreviousEntryId[entry.measurement.order]
      delete this.submittedTrackerByPreviousEntryId[entry.measurement.order]
      delete this.submittedTrackerByPreviousEntryId[entry.measurement.previous_inventory_order]
      this.submittedTrackerByPreviousEntryId = { ...this.submittedTrackerByPreviousEntryId }

      if (entry.id) {
        this.$emit('refreshEntry', { ...entry, REMOVE: true })
      }

      this.saveInventoryLocation({
        type: 'item_delete',
        value: entry.measurement
      })

      setTimeout(() => {
        this.$emit('refresh')
      }, 1000)
    },
    submitAllFromPreviousLocation() {
      if (this.previousInventoryArchivedItems.length <= 1) {
        this.previousInventoryMeasurements.forEach(entry => {
          this.submittedTrackerByPreviousEntryId[entry.measurement.order] = true
          this.hiddenTrackerByPreviousEntryId[entry.measurement.order] = true

          let order = getId(),
            measurement = {
              ...entry.measurement,
              inventory_id: this.inventoryId,
              inventory_order: order,
              id: order,
              order,
              measurement_id: order,
              previous_inventory_order: entry.measurement.order
            },
            newEntry = merge({}, entry, {
              inventory_order: order,
              inventory_id: this.inventoryId,
              id: order,
              measurement,
              FAKE: true
            })

          this.$emit('refreshEntry', newEntry)
        })

        this.submittedTrackerByPreviousEntryId = { ...this.submittedTrackerByPreviousEntryId }
        this.currentInventoryMeasurements = [...this.previousInventoryMeasurements]

        this.saveInventoryLocation({ type: this.viewMode === 'previousInventoryWithMovements' ? 'submit_previous_with_movements' : 'submit_previous' })

        if (this.previousInventoryArchivedItems.length === 1 && this.$refs.confirmDialogArchivedItems) {
          this.$refs.confirmDialogArchivedItems.confirm({
            callback: () => { },
            message: this.translations.translate('tplInventoriesSubmitAllWithSingleArchivedItem', { '{a}': this.previousInventoryArchivedItems[0] }),
            title: this.translations.txtInventoriesSubmitAll
          })
        }
      }
    },
    wrapOperationForOfflineCollection(operation, type, typeId, subType = null, subTypeId = null) {
      console.log('operation, type, typeId', operation, type, typeId)
      return {
        type,
        subtype: subType,
        type_id: typeId,
        secondary_type_id: subTypeId,
        user_id: this.user.id,
        venue_id: this.venue.id,
        value: [{ ...operation, date: new Date().toISOString(), platform: 'web' }]
      }
    },
    prepareMeasurements: debounce(function debounceHandler() {
      this.submittedTrackerByPreviousEntryId = {}
      this.submittedTracker = {}

      if (Array.isArray(this.entries)) {
        this.currentInventoryMeasurements = []

        this.entries.forEach(entry => {

          if (entry.inventory_id === this.inventoryId) {
            let variantId = get(entry, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(entry, 'measurement.item_id'), 'prepareMeasurements 499'))

            this.submittedTrackerByPreviousEntryId[entry.measurement.previous_inventory_order] = true

            this.submittedTracker[variantId] = this.submittedTracker[variantId] || 0
            this.submittedTracker[variantId] += get(entry, 'units_computed', 0)
            this.submittedTracker[variantId] = round(this.submittedTracker[variantId], 2)

            this.currentInventoryMeasurements.push({ ...entry, measurement: { ...entry.measurement, item_distributor_id: variantId } })
          }
        })

        this.initDoneCurrent = true
        this.initDone = this.initDonePrevious && this.initDoneCurrent
        console.log('initdone', 'prepareMeasurements', this.initDone)

        this.submittedTracker = { ...this.submittedTracker }
        this.submittedTrackerByPreviousEntryId = { ...this.submittedTrackerByPreviousEntryId }

        if (this.debug) {
          console.log('prepareMeasurements', this.currentInventoryMeasurements)
        }
      }
    }, 200),
    getVariantIdFromItemId(itemId, caller) {
      if (this.debug) {
        console.log('getVariantIdFromItemId', itemId, caller)
      }
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids[0]
    },
    mapVariantAsItemsToCategories() {
      this.items = this.itemVariants.filter(v => v && v.inventoriable).map(v => ({ ...prepareVariantAsItem(v, this.$store.state) }))
      this.mapItemsToCategories()
    },
    mapItemsToCategories: debounce(function debounceHandler(openFirstCategory = false) {
      if (this.viewMode === 'items') {
        let categories = {},
          itemsByCategoryMeasurementsByItemVariantId = {}
        this.filteredItems.filter(b => b && !b.archived && !b.grouped_to).forEach(i => {
          if (categories[i.category_id]) {
            categories[i.category_id].push(i)
          } else {
            categories[i.category_id] = [i]
          }
          itemsByCategoryMeasurementsByItemVariantId[i.item_distributor_id] = { measurement: { measurement: { type: 'unit', quantity: 0, ml_left: 0 } } }
        })

        categories = Object.keys(categories)
          .filter(key => key && this.categoriesById[key])
          .map(ck => ({
            id: ck,
            title: `${this.familiesById[this.categoriesById[ck].family_id]?.title || ''} > ${this.categoriesById[ck]?.title || ''}`,
            items: categories[ck]
          }))

        this.itemsByCategory = categories
        this.itemsByCategoryMeasurementsByItemVariantId = itemsByCategoryMeasurementsByItemVariantId
        this.mapItemsToCategoriesInProgress = false

        if (openFirstCategory) {
          this.categoryAccordion = 0
        }
      }
    }, 500),
    loadPreviousMeasurements: debounce(function debounceHandler(caller) {
      if (this.viewMode !== 'items') {
        console.log('loadPreviousMeasurements', caller)
        let map = { previousInventoryWithMovements: 'measurementsWithMovements', previousInventory: 'measurements' },
          previousInventoryMeasurementsByLocationId = {}

        if (this.inventoryData) {
          if (this.inventoryData.previous && this.inventoryData.previous.locations) {
            previousInventoryMeasurementsByLocationId.measurements = {}
            this.inventoryData.previous.locations.forEach(location => {
              previousInventoryMeasurementsByLocationId.measurements[location.location.id] = location.measurements.map(entry => ({
                inventory_order: entry.order,
                measurement: entry,
                id: entry.order,
                inventory_id: this.previousInventoryId,
                location_id: location.location.id
              }))
            })
          }
          if (this.inventoryData.previous_with_movements && this.inventoryData.previous_with_movements.locations) {
            previousInventoryMeasurementsByLocationId.measurementsWithMovements = {}
            this.inventoryData.previous_with_movements.locations.forEach(location => {
              previousInventoryMeasurementsByLocationId.measurementsWithMovements[location.location.id] = location.measurements.map(entry => ({
                inventory_order: entry.order,
                measurement: entry,
                id: entry.order,
                inventory_id: this.previousInventoryId,
                location_id: location.location.id
              }))
            })
          }
        }

        if (previousInventoryMeasurementsByLocationId && this.locationId && (this.viewMode === 'previousInventory' || this.viewMode === 'previousInventoryWithMovements')) {
          let previousInventoryMeasurements = []
          let previousInventoryArchivedItems = []

          get(previousInventoryMeasurementsByLocationId, `${map[this.viewMode]}.${this.locationId}`, [])
            .sort((a, b) => compareNumbers(a.inventory_order, b.inventory_order))
            .forEach(entry => {
              let variantId = get(entry, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(entry, 'measurement.item_id'), 'loadPreviousMeasurements 624')),
                item = prepareVariantIdAsItem(variantId, this.$store.state) || {}

              if (item && item.variant) {
                let isArchived = item.archived || item.variant.archived,
                  isHidden = this.hiddenTrackerByPreviousEntryId[entry.measurement.order],
                  isSubmitted = this.submittedTrackerByPreviousEntryId[entry.measurement.order]

                if (!isHidden && !isSubmitted && !isArchived && objectFilter({ payload: item, query: this.itemsSearchQuery })) {
                  previousInventoryMeasurements.push({
                    ...entry,
                    measurement: { ...entry.measurement, item_distributor_id: variantId },
                    item
                  })
                }

                if (isArchived) {
                  previousInventoryArchivedItems.push(item.title + ' ' + item.titleSuffix)
                }
              }
            })

          this.previousInventoryMeasurements = previousInventoryMeasurements
          this.previousInventoryArchivedItems = previousInventoryArchivedItems
          this.previousInventoryMeasurementsByEntryId = arrayToObjectById(this.previousInventoryMeasurements)
        }

        if (this.viewMode === 'previousInventoryHidden') {
          this.previousInventoryMeasurements = get(previousInventoryMeasurementsByLocationId, `measurements.${this.locationId}`, [])
            .map(m => {
              let variantId = get(m, 'measurement.item_distributor_id', this.getVariantIdFromItemId(get(m, 'measurement.item_id'), 'loadPreviousMeasurements 645'))

              return {
                ...m,
                measurement: { ...m.measurement, item_distributor_id: variantId },
                item: prepareVariantIdAsItem(variantId, this.$store.state) || {}
              }
            })
            .filter(
              entry =>
                this.hiddenTrackerByPreviousEntryId[entry.measurement.order] &&
                entry.item &&
                entry.item.variant &&
                !entry.item.archived &&
                !entry.item.variant.archived &&
                objectFilter({ payload: entry.item, query: this.itemsSearchQuery })
            )
          this.previousInventoryMeasurementsByEntryId = arrayToObjectById(this.previousInventoryMeasurements)
        }

        this.initDonePrevious = true
        this.initDone = this.initDonePrevious && this.initDoneCurrent

        if (this.inventoryItemMeasurementsDialogVisible && this.selectedItemId) {
          this.openSelectedEntriesDialog({ measurement: { item_id: this.selectedItemId } })
        }

        console.log('initdone', 'loadPreviousMeasurements', this.initDone)
      } else {
        this.initDonePrevious = true
        this.initDone = true
      }
    }, 500)
  },
  created() {
    if (this.recentInventoriesById[this.inventoryId]) {
      this.inventoryPairData = this.recentInventoriesById[this.inventoryId].pair

      if (this.venue.transfers && this.inventoryPairData.previous_with_movements) {
        this.viewMode = 'previousInventoryWithMovements'
      }

      if (!this.inventoryPairData.previous) {
        this.viewMode = 'items'
      }
    }
  },
  beforeUnmount() {
    this.unsubscribers.forEach(z => {
      if (typeof z === 'function') {
        z()
      }
    })
  },
  watch: {
    bottlesById: {
      immediate: true,
      handler: 'mapVariantAsItemsToCategories'
    },
    itemVariants: {
      immediate: false,
      handler: 'mapVariantAsItemsToCategories'
    },
    inventoryItemMeasurementsDialogVisible(inventoryItemMeasurementsDialogVisible) {
      if (!inventoryItemMeasurementsDialogVisible) {
        this.selectedInventoryEntries = []
        this.selectedItemId = null
      }
    },
    inventoryData() {
      this.loadPreviousMeasurements('inventoryData')
    },
    submittedTrackerByPreviousEntryId() {
      this.loadPreviousMeasurements('submittedTrackerByPreviousEntryId')
    },
    hiddenTrackerByPreviousEntryId() {
      this.loadPreviousMeasurements('hiddenTrackerByPreviousEntryId')
    },
    entries: 'prepareMeasurements',
    locationId: {
      immediate: true,
      handler(locationId) {
        console.log('locationId', locationId)
        if (locationId) {
          this.loadPreviousMeasurements('locationId')
          this.prepareMeasurements()
        } else {
          this.previousInventoryMeasurements = []
          this.currentInventoryMeasurements = []

          this.unsubscribers.forEach(z => {
            if (typeof z === 'function') {
              z()
            }
          })
        }
      }
    },
    viewMode() {
      this.mapItemsToCategories(true)
      this.loadPreviousMeasurements('viewMode')
    },
    families: {
      immediate: true,
      handler() {
        this.mapItemsToCategories()
      }
    },
    itemsSearchQuery() {
      if (this.viewMode === 'items') {
        this.mapItemsToCategoriesInProgress = true
        this.itemsByCategory = []
        this.itemsByCategoryMeasurementsByItemVariantId = {}
        this.mapItemsToCategories(true)
      }
      this.loadPreviousMeasurements('itemsSearchQuery')
    }
  }
}
</script>

<style lang="scss"></style>
